<template>
  <div class="table-wrapper d-flex">
    <div class="table-top d-flex align-center justify-space">
      <h2 class="title bold">{{ title }}</h2>
      <div class="table-filters d-flex align-center" v-if="filters">
        <div class="table-sort mr-80">
          <span class="icon icon-sort mr-10"></span>
          <span class="semi-14 color-table">{{ $t('sort') }}</span>
        </div>
        <div class="table-filter">
          <span class="icon icon-filter mr-10"></span>
          <span class="semi-14 color-table">{{ $t('filter') }}</span>
        </div>
      </div>
    </div>
    <table class="table">
      <slot name="header"></slot>
      <slot name="data"></slot>
    </table>
    <slot name="no-data"></slot>
    <span class="padding"></span>
  </div>
</template>

<script>
export default {
  name: 'TableComponent',
  props: {
    title: {
      type: String,
      default: '',
    },
    filters: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" src="./TableComponent.scss"></style>
