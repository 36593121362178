<template>
  <div class="reset">
    <navbar-component class="justify-center" />
    <div class="reset-wrapper d-flex">
      <div class="reset-left">
        <div class="img-wrapper">
          <img src="~@/assets/imgs/login.svg" alt="side img" />
        </div>
      </div>
      <div class="reset-rigth">
        <h2 class="reset-title">{{ $t('forgotPassword') }}</h2>
        <form class="form" @submit.prevent="forgotPassword">
          <div class="form__input">
            <label class="semi-16 color-black">
              {{ $t('emailAddress') }}
            </label>
            <input
              required
              type="email"
              class="color-gray-warm"
              v-model="email"
              :placeholder="$t('emailAddress')"
            />
          </div>
          <button class="form__submit semi-16 color-white" type="submit">
            {{ $t('submit') }}
          </button>
          <div class="auth-language">
            <LanguageSelector />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent';
import LanguageSelector from '@/components/LanguageSelector/LanguageSelector.vue';
import api from '@/api';
export default {
  name: 'forgotPassword',
  components: {
    NavbarComponent,
    LanguageSelector,
  },
  data() {
    return {
      email: null,
    };
  },
  methods: {
    async forgotPassword() {
      const postBody = {
        email: this.email,
        user: {
          service: {
            reset_password_form_uri: `${location.origin}/reset-password`,
          },
        },
      };
      await api.getCookie();
      const res = await api.forgotPassword(postBody);
      console.log(res);
      const { data } = res;
      const { messages, code } = data;
      if (code === 422) {
        const { error } = messages;
        this.$toast.error(error, this.toastOption);
        return;
      }
      const { general } = messages;
      this.$toast.success(general, this.toastOption);
    },
  },
};
</script>

<style lang="scss" src="./ForgotPasswordView.scss" scoped></style>
