<template>
  <div class="sidebar-wrapper">
    <slot name="toggle-btn"></slot>
    <div class="sidebar-holder">
      <aside class="sidebar">
        <ul class="sidebar-list">
          <router-link
            v-if="isMobile"
            tag="li"
            to="/"
            class="d-flex align-center pointer"
          >
            <div class="d-flex align-center medium-16 color-black-light">
              <span :class="`icon icon-home mr-10`"></span>
              {{ $t('home') }}
            </div>
          </router-link>
          <li
            v-if="isMobile"
            class="d-flex align-center pointer"
            @click="userProfileToggle"
          >
            <div class="d-flex align-center medium-16 color-black-light">
              <span class="mr-10 sidebar-user">
                <img
                  :src="userData.profile_photo"
                  alt="user"
                  v-if="userData.profile_photo !== ''"
                />
                <span v-else class="icon icon-avatar"></span>
                <!-- <input
                  type="file"
                  style="display: none"
                  ref="fileInput"
                  @change="onFileChange"
                  accept="image/*"
                /> -->
              </span>
              {{ $t('myProfile') }}
            </div>
          </li>
          <li class="user-profile" :class="{ active: isActive }">
            <p class="semi-16">{{ userData.name }}</p>
            <p class="normal-16">XBody admin studio</p>
          </li>
          <router-link
            v-if="isMobile"
            tag="li"
            to="#"
            class="d-flex align-center pointer"
          >
            <div class="d-flex align-center medium-16 color-black-light">
              <span class="icon icon-call mr-10"></span>
              {{ $t('support') }}
            </div>
          </router-link>
          <router-link
            v-for="link in links"
            :key="link.link"
            tag="li"
            custom
            :to="link.link"
            :class="link.disabled ? 'disabled' : ''"
            class="d-flex align-center pointer"
          >
            <div class="d-flex align-center medium-16 color-black-light">
              <span :class="`icon icon-${link.icon} mr-10`"></span>
              {{ link.name }}
            </div>
          </router-link>
          <li class="d-flex align-center" @click="logout" v-if="isMobile">
            <div class="normal-16 color-gray-dark d-flex align-center">
              <div class="icon-wrapper mr-10">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.375 12.125L2.625 12.125M16.125 21.125L21.375 21.125L21.375 3.125L16.125 3.125L16.125 21.125ZM7.875 17.375L2.625 12.125L7.875 6.875L7.875 17.375Z"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              {{ $t('sign_out') }}
            </div>
          </li>
        </ul>
      </aside>
      <div class="language-selector">
        <LanguageSelector />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LanguageSelector from '@/components/LanguageSelector/LanguageSelector.vue';
import api from '@/api';

export default {
  components: {
    LanguageSelector,
  },
  name: 'SidebarComponent',
  data() {
    return {
      links: [
        {
          name: this.$t('settings'),
          link: '/settings',
          icon: 'settings',
          disabled: false,
        },
        {
          name: this.$t('manageConsents'),
          link: '/manage',
          icon: 'lock',
          disabled: true,
        },
        {
          name: this.$t('checkActiveApplications'),
          link: '/check-active',
          icon: 'check',
          disabled: false,
        },
        {
          name: this.$t('joinSocialAccounts'),
          link: '/social-accounts',
          icon: 'join',
          disabled: true,
        },
        {
          name: this.$t('invoices'),
          link: '/invoices',
          icon: 'dollar',
          disabled: false,
        },
      ],
      isOpen: true,
      isMobile: false,
      isActive: false,
    };
  },
  methods: {
    ...mapActions(['updateUserLoggedIn', 'updateUserData']),
    userProfileToggle() {
      this.isActive = !this.isActive;
    },
    async logout() {
      await api.getCookie();
      const { data } = await api.logout();
      if (data) {
        const { messages } = data;
        const { general } = messages;
        this.$toast.success(general);
        localStorage.clear();
        this.$router.push({ name: 'Login' });
      }
    },
    onResize() {
      this.isMobile = document.body.clientWidth <= 768;
    },
  },
  computed: {
    ...mapGetters(['userData']),
  },
  mounted() {
    this.onResize();
    window.onresize = this.onResize;
  },
};
</script>

<style lang="scss" src="./SidebarComponent.scss"></style>
