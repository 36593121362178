<template>
  <label class="wrapper semi-16 color-gray-dark d-flex align-center">
    <p>
      {{ label }}
      <a :href="url" target="_blank" @click.stop class="link color-red">{{
        urlText
      }}</a>
    </p>
    <input class="checkbox" type="checkbox" v-model="model" :value="true" />
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  name: 'CheckboxComponent',
  props: {
    label: {
      type: String,
      default: 'test',
    },
    urlText: {
      type: String,
      default: 'Test',
    },
    url: {
      type: String,
      default: 'test url',
    },
    value: {
      type: Boolean,
      default: false,
    },
    inputValue: {
      type: [Boolean, String],
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" src="./CheckboxComponent.scss" scoped></style>
