<template>
  <header class="nav-wrapper d-flex align-end">
    <router-link to="/" class="nav-logo">
      <img src="~@/assets/icons/logo.svg" alt="logo" />
    </router-link>
    <slot name="actions"></slot>
  </header>
</template>

<script>
export default {
  name: 'NavbarComponent',
};
</script>

<style lang="scss" src="./NavbarComponent.scss"></style>
