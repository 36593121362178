<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <form
        class="modal-wrapper d-flex align-center justify-center"
        @submit.prevent="submit"
      >
        <div class="modal-container">
          <slot name="close-btn"></slot>
          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <slot name="modal-body"></slot>

          <slot name="footer"></slot>
        </div>
      </form>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'ModalComponent',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit() {
      console.log('submit');
    },
  },
};
</script>

<style lang="scss" src="./ModalComponent.scss"></style>
