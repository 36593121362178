<template>
  <span class="chip color-green" :class="{ border: outlined }">
    <span class="medium-20" :class="{ 'regular-12': outlined }">{{
      text
    }}</span>
  </span>
</template>

<script>
export default {
  name: 'ChipComponent',
  props: {
    text: {
      type: String,
      required: true,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" src="./ChipComponent.scss" scoped></style>
