<template>
  <div class="workspace">
    <navbar-component>
      <template #actions>
        <div class="nav-actions d-flex align-center justify-space">
          <router-link
            to="/"
            tag="div"
            class="nav-home d-flex-inline align-center pointer"
          >
            <span class="icon icon-home mr-10"></span>
            <span class="regular-16 color-gray-dark text">
              {{ $t('home') }}
            </span>
          </router-link>
          <div class="nav-users d-flex-inline align-center">
            <div class="nav-user mr-10 pointer">
              <div class="nav-user-img">
                <img
                  :src="userData.profile_photo"
                  alt="user"
                  v-if="userData.profile_photo !== ''"
                />
                <span v-else class="icon icon-avatar user-image"></span>
              </div>
              <ul class="nav-user-actions" :class="{ active: isActive }">
                <li class="" @click="$router.push({ name: 'Settings' })">
                  <p class="semi-16 color-gray-dark">{{ userData.name }}</p>
                  <p class="semi-16 color-gray-dark">XBody admin studio</p>
                </li>
                <li class="d-flex align-center" @click="logout">
                  <div class="semi-16 color-gray-dark d-flex align-center">
                    <div class="icon-wrapper mr-10">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.375 12.125L2.625 12.125M16.125 21.125L21.375 21.125L21.375 3.125L16.125 3.125L16.125 21.125ZM7.875 17.375L2.625 12.125L7.875 6.875L7.875 17.375Z"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    {{ $t('sign_out') }}
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="ml-10 nav-burger-menu"
              :class="{ active: isOpen }"
              @click="toggle"
            >
              <svg
                width="30"
                height="20"
                viewBox="0 0 30 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 20V16.6667H30V20H0ZM0 11.6667V8.33333H30V11.6667H0ZM0 3.33333V0H30V3.33333H0Z"
                  fill="#DAE0E4"
                />
              </svg>
            </div>
          </div>
        </div>
      </template>
    </navbar-component>
    <div class="workspace-wrapper" :class="{ active: isOpen }">
      <div class="sidebar-btn open" v-if="!isOpen">
        <button type="button" @click="toggle">
          <span class="icon icon-left"></span>
        </button>
      </div>
      <sidebar-component>
        <template #toggle-btn>
          <div class="sidebar-btn">
            <button type="button" @click="toggle">
              <span class="icon icon-left"></span>
            </button>
          </div>
        </template>
      </sidebar-component>
      <side-bar-icons-component
        :isOpen="!isOpen"
        v-if="!isOpen"
      ></side-bar-icons-component>
      <main>
        <router-view :key="$route.path" />
      </main>
    </div>
  </div>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent';
import SidebarComponent from '@/components/SidebarComponent';
import SideBarIconsComponent from '@/components/SideBarIconsComponent';
import { mapActions, mapGetters } from 'vuex';
import api from '@/api';

export default {
  name: 'HomeView',
  components: {
    NavbarComponent,
    SidebarComponent,
    SideBarIconsComponent,
  },
  data() {
    return {
      isOpen: true,
      isActive: false,
    };
  },
  methods: {
    ...mapActions(['updateUserLoggedIn', 'updateUserData']),
    async logout() {
      await api.getCookie();
      const { data } = await api.logout();
      if (data) {
        const { messages } = data;
        const { general } = messages;
        this.$toast.success(general);
        localStorage.clear();
        this.$router.push({ name: 'Login' });
      }
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    mouseover() {
      this.isActive = true;
    },
    mouseleave() {
      this.isActive = false;
    },
    sidebarToggle() {
      this.isOpen = false;
    },
  },
  watch: {
    $route() {
      if (screen.width <= 768) {
        this.sidebarToggle();
      }
    },
  },
  computed: {
    ...mapGetters(['userData']),
  },
  mounted() {
    const width = screen.width;
    if (width <= 768) {
      this.isOpen = false;
    }
  },
};
</script>

<style lang="scss" src="./WorkspaceView.scss"></style>
