<template>
  <div class="reset">
    <navbar-component class="justify-center" />
    <div class="reset-wrapper d-flex">
      <div class="reset-left">
        <div class="img-wrapper">
          <img src="~@/assets/imgs/login.svg" alt="side img" />
        </div>
      </div>
      <div class="reset-rigth">
        <h2 class="reset-title">{{ $t('resetPassword') }}</h2>
        <p class="reset-subtitle semi-16 color-gray-cool">
          {{ $t('resetRequest') }}
        </p>
        <form class="form">
          <p class="reset-forgot semi-16 color-gray-dark">
            {{ $t('gotEmail') }}
          </p>
          <button class="form__submit semi-16 color-white" type="submit">
            {{ $t('resend') }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent';

export default {
  name: 'ResetComfirm',
  components: {
    NavbarComponent,
  },
  data() {
    return {
      isEmail: true,
      isSamePassword: true,
      postObject: {
        email: null,
        password: null,
        newPassword: null,
      },
    };
  },
  methods: {
    goResetPassword() {
      this.$router.push({ name: 'ResetPassword' });
    },
  },
};
</script>

<style lang="scss" src="./ResetComfirmView.scss" scoped></style>
