<template>
  <div class="manage-wrapper page page-padding">
    <modal-component :show="showModal" @close="closeModal">
      <template #modal-body>
        <h3 class="medium-24 color-gray-dark">
          {{ $t('deleteItem') }}
        </h3>
      </template>
      <template #footer>
        <div class="modal-footer mt-40 d-flex aling-center justify-center">
          <button
            type="button"
            class="semi-16 color-gray-dark mr-40"
            @click="closeModal"
          >
            {{ $t('yes') }}
          </button>
          <button
            type="button"
            class="semi-16 color-gray-dark"
            @click="closeModal"
          >
            {{ $t('no') }}
          </button>
        </div>
      </template>
    </modal-component>
    <table-component title="Manage GDPR consents" :filters="false">
      <template #header>
        <thead class="table-header">
          <tr>
            <th
              v-for="(header, index) in tableHeader"
              :key="index"
              class="semi-18 color-gray-cool"
              :class="`text-align-${header.aling}`"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
      </template>
      <template #data v-if="tableData.length > 0">
        <tbody class="table-body">
          <tr v-for="data in tableData" :key="data.id">
            <td class="medium-20 color-gray-dark text-align-center">
              {{ data.date }}
            </td>
            <td class="medium-20 color-gray-dark text-align-center">
              {{ data.name }}
            </td>
            <td class="medium-20 color-gray-dark text-align-center">
              {{ data.appName }}
            </td>
            <td class="medium-20 color-gray-dark text-align-center">
              {{ data.money }}
            </td>
            <td>
              <button type="button" class="table-btn" @click="openModal">
                <span class="regular-16 color-white">
                  {{ $t('deleteAccess') }}
                </span>
              </button>
            </td>
          </tr>
        </tbody>
      </template>
      <template #no-data v-else>
        <div class="d-flex align-center justify-center mt-60">
          <h2 class="medium-24 color-gray-cool">{{ $t('noData') }}</h2>
        </div>
      </template>
    </table-component>
  </div>
</template>

<script>
import TableComponent from '@/components/TableComponent';
import ModalComponent from '@/components/ModalComponent';
import { mapActions } from 'vuex';

export default {
  name: 'ManageView',
  components: {
    TableComponent,
    ModalComponent,
  },
  data() {
    return {
      tableHeader: [
        {
          text: this.$t('date'),
          aling: 'center',
        },
        {
          text: this.$t('name'),
          aling: 'center',
        },
        {
          text: this.$t('nameOfApplication'),
          aling: 'center',
        },
        {
          text: this.$t('givenContributions'),
          aling: 'center',
        },
        {
          text: this.$t('delete'),
          aling: 'center',
        },
      ],
      showModal: false,
      tableData: [],
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    ...mapActions(['updateShowLoader']),
  },
  mounted() {
    this.updateShowLoader(false);
  },
};
</script>

<style lang="scss" src="./ManageView.scss" scoped></style>
