<template>
  <div class="social-wrapper">
    <h2 class="social-title semi-40 color-dark-gray">
      {{ $t('welcome') }}, <span>{{ userData.name }}!</span>
    </h2>

    <p class="social-description medium-20 color-dark-gray">
      {{ $t('newFeature') }}
    </p>

    <div class="social-links-wrapper">
      <div class="social-logo">
        <div class="img-wrapper">
          <img src="~@/assets/icons/social-logo.svg" alt="logo" />
        </div>
      </div>
      <div class="social-links">
        <div class="social-links-inner">
          <div class="social-links__item">
            <a href="https://www.facebook.com/xbodyworld" target="_blank">
              <span class="icon icon-facebook"></span>
            </a>
          </div>
          <div class="social-links__item">
            <a href="https://www.instagram.com/xbody_world" target="_blank">
              <span class="icon icon-instagram"></span>
            </a>
          </div>
          <div class="social-links__item">
            <a
              href="https://www.linkedin.com/company/xbody-world"
              target="_blank"
            >
              <span class="icon icon-linkedin"></span>
            </a>
          </div>
          <div class="social-links__item">
            <a href="#" target="_blank">
              <span class="icon icon-twitter"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SocialAccountsView',
  methods: {
    ...mapActions(['updateShowLoader']),
  },
  computed: {
    ...mapGetters(['userData']),
  },
  mounted() {
    this.updateShowLoader(false);
  },
};
</script>

<style lang="scss" src="./SocialAccountsView.scss" scoped></style>
