<template>
  <div>
    <navbar-component class="justify-center" />
    <div class="elementor-widget-container">
      <p>Last updated: 15-04-2024</p>
      <p></p>
      <h2>PRIVACY POLICY</h2>
      <p></p>
      <h3>1. PREAMBLE</h3>
      <p></p>
      <p>
        At XBody International Ltd. (hereinafter referred to as “<strong
          >XBody International</strong
        >”), we want to give you the best possible experience to ensure that you
        enjoy our service today and in the future. We appreciate that you are
        trusting us with information that is important for you, and we believe
        that transparency is the key to any healthy relationship. So, we want to
        transparently explain how and why we gather, store, share and use your
        personal data, outline the controls and choices you have around when as
        well as the measure we take to keep your personal data safe.
      </p>
      <p></p>
      <p>
        The highest level of protection of personal data is a priority for XBody
        International Ltd. (seat: H- 1072 Budapest, Rákóczi u. 42., contact
        details:&nbsp;<a href="https://xbodyworld.com/contact-us"
          >https://xbodyworld.com/contact-us</a
        >, or +36 96&nbsp;200&nbsp;180)
      </p>
      <p></p>
      <p>
        This Privacy Policy aims to give information to the visitors of
        www.xbodyworld.com website (hereinafter referred to as the
        “<strong>Website</strong>“) on the data processing related to the
        operation of XBody International. XBody International’s data processing
        is in accordance with the Regulation (EU) 2016/679 of the European
        Parliament and of the Council of 27 April 2016 on the protection of
        natural persons with regard to the processing of personal data and on
        the free movement of such data, and repealing Directive 95/46/EC
        (General Data Protection Regulation, hereinafter referred to as the
        “<strong>GDPR</strong>“) and the Act CXII of 2011 on the Right of
        Informational Self-Determination and on Freedom of Information
        (hereinafter referred to as the “<strong>Privacy Act</strong>“).
      </p>
      <p></p>
      <p>
        XBody International, as data controller, accepts the content of this
        legal notice as mandatory. XBody International commits to comply its
        data processing to the present Privacy Policy and to the legislation in
        force.
      </p>
      <p></p>
      <p>We hereby inform you on the main aspects of our data processing:</p>
      <p></p>
      <h3>2. GENERAL PROVISIONS</h3>
      <p></p>
      <p>
        The principles of XBody International data processing comply with the
        existing data protection legislation, in particular:
      </p>
      <p></p>
      <ul>
        <li>GDPR,</li>
        <li>Privacy Act.,</li>
        <li>
          Act V of 2013 on the Civil Code (“<strong>Civil Code</strong>“),
        </li>
        <li>
          Act CVIII of 2001 on Electronic Commerce and on Information Society
          Services,
        </li>
        <li>Act C of 2003 – on Electronic Communications,</li>
        <li>
          Act XLVIII of 2008 on the Basic Requirements and Certain Restrictions
          of Commercial Advertising Activities
        </li>
        <li>
          Act CLV of 1997 on Customer Protection (“<strong
            >Act on Consumer Protection</strong
          >”)
        </li>
        <li>
          Act C of 2000 on Accounting (“<strong>Act on Accounting</strong>”)
        </li>
      </ul>
      <p>
        XBody International&nbsp;<strong
          >does not process special categories of personal data</strong
        >&nbsp;for the purposes specified in this Privacy Policy.
      </p>
      <p>
        For processing your personal data, XBody International uses the
        following processors:
      </p>
      <p></p>
      <ul>
        <li>
          <strong>The Rocket Science Group LLC</strong>&nbsp;(675 Ponce de Leon
          Ave NE, Suite 5000, Atlanta, GA 30308 USA) for sending our newsletters
          by operating the MailChimp platform as defined in Point 3.2 below
          (legal newsletter service),
        </li>
        <li>
          <strong>Gloster Cloud Zrt.</strong>&nbsp;(2142 Nagytarcsa, Csonka
          János utca 1/A. A/2. ép.) as web hosting service provider for
          operating our website and as cloud provider
        </li>
        <li>
          <strong>WebOrigo Magyarország Zrt.</strong>&nbsp;(1027 Budapest, Bem
          József utca 9. fszt.) for providing IT support in relation to the
          operation of our website
        </li>
      </ul>
      <p>
        XBody International only discloses your processed personal data to the
        members and employees of XBody International whose access to your
        personal data is vital to the achievement of the purpose of processing.
      </p>
      <p></p>
      <p>
        XBody International processes your personal data in confidence and
        implements appropriate technical and organisational measures to ensure
        their protection. Furthermore, XBody International creates appropriate
        procedures provisions that are necessary to implement the regulations of
        GDPR, the Privacy Act and other domestic and international data
        protection laws.
      </p>
      <p></p>
      <p>
        XBody International reserves the right to modify the present Privacy
        Policy at any time. Naturally, we will notify our partners and customers
        in sufficient time of any changes.
      </p>
      <p></p>
      <h3>3. SPECIAL PROVISIONS APPLIED TO OUR DATA PROCESSING</h3>
      <p></p>
      <h4>3.1. Partner Portal registration data processing</h4>
      <p></p>
      <p>
        Visitors of the Website have the opportunity to register to our partner
        portal.
      </p>
      <p></p>
      <p>
        <strong>Purpose of processing:</strong>&nbsp;to register and perform
        orders, to keep records of partners and clients, to document orders and
        payments.
      </p>
      <p></p>
      <p>
        <strong>Legal basis of processing:</strong>&nbsp;In case an order is
        placed it is the performance of contract (Article 6 (1) (b) of GDPR) and
        compliance with legal obligation (Act on Accounting section 2 Article
        169; GDPR point c) section 1 of Article 6), in case there is no order
        placed it is the freely given consent of the data subject (Article 6 (1)
        (b) of GDPR).
      </p>
      <p></p>
      <p>
        <strong>Scope of the processing:</strong>&nbsp;name and contact details
        (phone number, e-mail address) of the data subject, name of the
        organization, country.
      </p>
      <p></p>
      <p>
        <strong>Data retention period:</strong>&nbsp;in case of accounting
        documentation 8 years; in other cases 5 years
      </p>
      <p></p>
      <p><strong>Data processors:</strong></p>
      <p></p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Seat</th>
            <th>Data processor’s task</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>XBody Online Kft.</td>
            <td>H-1072 Budapest, Rákóczi út 42.</td>
            <td>FAQ and download files update</td>
          </tr>
        </tbody>
      </table>
      <h4>3.2. Processing for marketing purposes</h4>
      <p></p>
      <p>
        <strong>3.2.1. XBody International’s newsletter service</strong>
      </p>
      <p></p>
      <p>
        We regularly send newsletters to our clients and business partners who
        register on our partner portal and build a database for business
        purposes.
      </p>
      <p></p>
      <p>
        <strong>Purpose of the processing:</strong>&nbsp;to inform our clients
        on the latest news regarding our services, awareness raising and
        relationship management.
      </p>
      <p></p>
      <p>
        <strong>Scope of the processing:</strong>&nbsp;name, e-mail address,
        phone number, the name of the company and the consent of the data
        subject (declaration of consent)
      </p>
      <p></p>
      <p>
        <strong>Legal basis of processing:</strong>&nbsp;The legal basis of
        XBody International’s data processing is the&nbsp;<strong
          >freely given consent</strong
        >&nbsp;of the data subject (Article 6 (1) (a) of GDPR).
      </p>
      <p></p>
      <p>
        <strong>Data retention period:</strong>&nbsp;XBody International only
        processes your collected personal data until it is compatible with its
        purposes, but no later than the withdrawal of consent by the data
        subject.
      </p>
      <p></p>
      <p>
        Please note that withdrawal of consent and the erasure or modification
        of personal data can be requested on the following contact details:
      </p>
      <p></p>
      <ul>
        <li>
          e-mail:&nbsp;<a href="mailto:helpdesk@xbodyworld.com"
            >helpdesk@xbodyworld.com</a
          >
        </li>
        <li>address: H-1072 Budapest, Rákóczi u. 42</li>
        <li>
          other:&nbsp;<a href="https://xbodyworld.com/contact-us/"
            >https://xbodyworld.com/contact-us/</a
          >
        </li>
      </ul>
      <p>
        <strong>The scope of the processing:</strong>&nbsp;name, e-mail address,
        phone number, the name of the company and the consent of the data
        subject (declaration of consent).
      </p>
      <p></p>
      <p>
        <strong>Data transfer:</strong>&nbsp;To the&nbsp;<strong
          >Rocket Science Group LLC d/b/a MailChimp</strong
        >&nbsp;acting as data processor (seat: 675 Ponce De Leon Ave NE, Suite
        5000, Atlanta, Georgia 30308) that is part of the&nbsp;<strong
          >EU-U.S. Privacy Shield Framework Agreement</strong
        >.
      </p>
      <p></p>
      <p>
        XBody International uses the servers of the Data Processor that are
        located in a third country.
      </p>
      <p></p>
      <p>
        Due to the fact that Data Processor is part of the&nbsp;<strong
          >EU-U.S. Privacy Shield Framework Agreement</strong
        >, the appropriate level of protection of personal data is ensured.
      </p>
      <p></p>
      <p>
        <strong
          >3.2.2. Sending invitations and other marketing materials in
          connection to the professional events of XBody International</strong
        >
      </p>
      <p></p>
      <p>
        We regularly organise professional events for our clients, business
        partners and other interested audiences. In order to create and organise
        these events, we send out invitations and other materials for them.
      </p>
      <p></p>
      <p>
        <strong>Purpose of the processing:</strong>&nbsp;assessing the
        customer’s needs, consideration of the comments, client relationship
        management, informing our clients on the latest news regarding our
        services.
      </p>
      <p></p>
      <p>
        <strong>Scope of the processing:</strong>&nbsp;name, e-mail address,
        phone number, the name of the company and the consent of the data
        subject (declaration of consent)
      </p>
      <p></p>
      <p>
        <strong>Legal basis of processing:</strong>&nbsp;The legal basis of
        XBody International’s data processing is the&nbsp;<strong
          >freely given consent</strong
        >&nbsp;of the data subject (Article 6 (1) (a) of GDPR).
      </p>
      <p></p>
      <p>
        <strong>Data retention period:</strong>&nbsp;XBody International only
        processes your collected personal data until it is compatible with its
        purposes, but no later than the withdrawal of consent by the data
        subject.
      </p>
      <p></p>
      <p>
        <strong
          >3.2.3. Processing for the purpose of business relationship
          management</strong
        >
      </p>
      <p></p>
      <p>
        Purpose of the processing: client relationship management and
        facilitating to get in contact with XBody International. In case you
        would like to contact us, you can do it on our contact details indicated
        in this Privacy Policy and on our website, or directly on our website at
        the following link: direct contact:&nbsp;<a
          href="https://xbodyworld.com/contact-us/"
          >https://xbodyworld.com/contact-us/</a
        >
      </p>
      <p></p>
      <p>
        <strong>Scope of the processing:</strong>&nbsp;name and contact details
        (phone number and e-mail address) of the data subjects, other personal
        data placed on a business card (position, company name), documents
        provided by the requesting party, the description of the request and the
        consent of the data subject (declaration of consent)
      </p>
      <p></p>
      <p>
        <strong>Legal basis of processing:</strong>&nbsp;The legal basis of
        XBody International’s data processing is the&nbsp;<strong
          >freely given consent</strong
        >&nbsp;of the data subject (Article 6 (1) (a) of GDPR).
      </p>
      <p></p>
      <p>
        <strong>Data retention period:</strong>&nbsp;XBody International only
        processes your collected personal data until it is compatible with its
        purposes, but&nbsp;<strong
          >no later than the withdrawal of consent</strong
        >&nbsp;by the data subject.
      </p>
      <p></p>
      <p><strong>3.2.4. Polls</strong></p>
      <p></p>
      <p>
        Customer satisfaction has utmost importance to us, therefore we may send
        you polls and questionnaires.
      </p>
      <p></p>
      <p>
        <strong>Purpose of processing:</strong>&nbsp;to recognize your opinion
        about our services.
      </p>
      <p></p>
      <p>
        <strong>Legal basis of processing:</strong>&nbsp;The legal basis of
        XBody International’s data processing is the&nbsp;<strong
          >freely given consent</strong
        >&nbsp;of the data subject (Article 6 (1) (a) of GDPR).
      </p>
      <p></p>
      <p>
        <strong>Scope of the processing:</strong>&nbsp;name and contact details
        (phone number, e-mail address) of the data subject, name of the
        organization, your opinion and the consent of the data subject
        (declaration of consent).
      </p>
      <p></p>
      <p>
        <strong>Data retention period:</strong>&nbsp;XBody International only
        processes your collected personal data until it is compatible with its
        purposes, but no later than the withdrawal of consent by the data
        subject.
      </p>
      <p></p>
      <p><strong>Data processors:</strong></p>
      <p></p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Seat</th>
            <th>Data processor’s task</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Zoho</td>
            <td>
              Zoho Corporation B.V.. Beneluxlaan 4B 3527 HT UTRECHT The
              Netherlands<br />
            </td>
            <td>send and receive of polls</td>
          </tr>
        </tbody>
      </table>
      <h4>3.3. Complaint handling, customer service</h4>
      <p></p>
      <p>
        Personal data in connection with complaint handling and customer service
        is processed jointly with XBody Online Ltd (seat: 1072 Budapest, Rákóczi
        út 42.).
      </p>
      <p></p>
      <p>
        <strong>Purpose of the processing:</strong>&nbsp;to handle quality and
        quantity complaints concerning XBody services or products, to provide
        product service operation. The complaint notice can be registered
        through email:&nbsp;<a href="mailto:helpdesk@xbodyworld.com"
          >helpdesk@xbodyworld.com</a
        >, telephone: +36 96&nbsp;200 180, personally, in our website
        at:&nbsp;<a href="https://xbodyworld.com/contact-us/"
          >https://xbodyworld.com/contact-us/</a
        >, or at&nbsp;<a href="https://partnerportal.xbodyworld.com/"
          >https://partnerportal.xbodyworld.com</a
        >&nbsp;our registered partners through their partner portal.
      </p>
      <p></p>
      <p>
        <strong>Legal basis of processing:</strong>&nbsp;performance of service
        (GDPR point b) section 1 of Article 6); compliance with legal obligation
        (Act on Consumer Protection Article 17/A; Act on Accounting section 2
        Article 169; GDPR point c) section 1 of Article 6); consent of data
        subject (Act on Consumer Protection Article 17/A; GDPR point a) section
        1 of Article 6)
      </p>
      <p></p>
      <p>
        <strong>Scope of the processing:</strong>&nbsp;name and contact details
        (phone number, e-mail address) of the data subject, name and price of
        the product, time of the purchase and complaint notification,
        description of defect, claim required to be validated and the mode of
        settlement.
      </p>
      <p></p>
      <p>
        <strong>Data retention period:</strong>&nbsp;invoices of the returned
        goods: 8 years; records of the complaint and the replies: 5 years;
        comments in the book of customers: 2 years;
      </p>
      <p></p>
      <p>
        <strong>Consequence of refusal of data provision:</strong>&nbsp;it would
        be impossible to investigate and settle the complaint
      </p>
      <p></p>
      <p><strong>Data processors:</strong></p>
      <p></p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Seat</th>
            <th>Data processor’s task</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Zoho</td>
            <td>
              Zoho Corporation B.V.. Beneluxlaan 4B 3527 HT UTRECHT The
              Netherlands
            </td>
            <td>
              IM IT system where complaint notifications are registered and
              handled.
            </td>
          </tr>
          <tr>
            <td>XBody Online</td>
            <td>H-1072 Budapest, Rákóczi út 42.</td>
            <td>External developer</td>
          </tr>
          <tr>
            <td>XBody Hungary Kft.</td>
            <td>H-1120 Budapest, György Aladár utca 35-39. alagsor 52.</td>
            <td>External Developer</td>
          </tr>
        </tbody>
      </table>
      <h4>3.4. Contacting XBody International</h4>
      <p></p>
      <p>
        <strong>Purpose of processing:</strong>&nbsp;In case data subject wishes
        to contact XBody International, it may do so through channels provided
        in present Policy or the website.
      </p>
      <p></p>
      <p>
        <strong>Legal basis of processing:</strong>&nbsp;The legal basis is the
        freely given consent of the data subject (Article 6 (1) (a) of GDPR), in
        case of complaint handling or sales service, compliance with legal
        obligation (Act on Consumer Protection Article 17/A; GDPR point c)
        section 1 of Article 6).
      </p>
      <p></p>
      <p>
        <strong>Scope of the processing:</strong>&nbsp;name and contact details
        (phone number, e-mail address) of the data subject, description of the
        matter, documents attached by the data subject.
      </p>
      <p></p>
      <p>
        <strong>Data retention period:</strong>&nbsp;records of the inquiry and
        the replies: 5 years; in other case until the withdrawal of consent.
      </p>
      <p></p>
      <p><strong>Data processors:</strong></p>
      <p></p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Seat</th>
            <th>Data processor’s task</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Zoho Corporation B.V.<br /></td>
            <td>Beneluxlaan 4B 3527 HT UTRECHT The Netherlands</td>
            <td>receive and send messages</td>
          </tr>
          <tr>
            <td>WebOrigo Magyarország Zrt.</td>
            <td>9. fszt., Bem József street, 1027 Budapest, Hungary</td>
            <td>website operation</td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>Data transfer:</strong>&nbsp;To Salesforce acting as data
        processor (seat: Salesforce.com EMEA Limited, village 9, floor 26
        Salesforce Tower, 110 Bishopsgate, London, UK, EC2N 4AY, that is part of
        the&nbsp;<strong>EU-U.S. Privacy Shield Framework Agreement</strong>.
      </p>
      <p></p>
      <h4>3.5. Processing on https://xbodyworld.com/ website</h4>
      <p></p>
      <p>
        <strong>3.5.1. Logging on the xbodyworld.com server</strong>
      </p>
      <p></p>
      <p>
        When visiting https://xbodycorld.com/, the webserver does not record any
        personal data.
      </p>
      <p></p>
      <p>Data logging for external service providers:</p>
      <p></p>
      <p>
        The portal’s html code contains links to external servers that are
        independent form XBody International. The external service provider’s
        server is directly connected to the user’s computer. Please be aware of
        the fact, that the aforementioned service providers are able to collect
        user data (e.g.: IP address, browser, operating system details, cursor
        movement, name of the visited page and the date of the visit) due to the
        direct communication with the user’s browser.
      </p>
      <p></p>
      <p>
        <strong
          >3.5.2. Cookies at&nbsp;<a
            href="https://xbodyworld.com/cookie-and-privacy-policy/#cookie-information"
            >https://xbodyworld.com/</a
          ></strong
        >
      </p>
      <p></p>
      <p>
        XBody International and other service partners install cookies (small
        piece of information) in order to identify and track users and to read
        them back during internet usage. If your browser returns a previously
        saved cookie, the cookie operator has the capacity to link the user’s
        current visit to the previous ones for websites where XBody
        International or the external service providers’ cookies are installed.
      </p>
      <p></p>
      <p>
        XBody International uses cookies for the technical operation of the
        Website, for sending targeted newsletters and for statistical purposes.
        We inform you that cookies do not damage your computer and they do not
        contain viruses.
      </p>
      <p></p>
      <p>
        Cookies can be deleted from your computer and be blocked in your
        browser. To manage cookies, generally you can visit the menu point Tools
        / Preferences of your browser, then you shall visit the Privacy /
        History / Custom Settings menu point where cookies are found under the
        name of cookies or tracing.<br />The following external service
        providers have installed cookies on our website:
      </p>
      <p></p>
      <ul>
        <li>Google Analytics,</li>
        <li>Google Ads</li>
        <li>Google Fonts</li>
        <li>Facebook Pixel,</li>
        <li>Instagram</li>
        <li>YouTube</li>
      </ul>
      <div>
        <ul
          style="
            padding-left: calc(2 * var(--global--spacing-horizontal));
            font-family: var(--list--font-family);
          "
        >
          <li>Mouse flow</li>
          <li>WordPress</li>
        </ul>
      </div>
      <ul>
        <li>Elementor</li>
      </ul>
      <div>
        <ul
          style="
            padding-left: calc(2 * var(--global--spacing-horizontal));
            font-family: var(--list--font-family);
          "
        >
          <li>LinkedIn</li>
        </ul>
      </div>
      <div>
        <ul
          style="
            padding-left: calc(2 * var(--global--spacing-horizontal));
            font-family: var(--list--font-family);
          "
        >
          <li>Consent Manager</li>
        </ul>
      </div>
      <p>
        <strong>3.5.3. Google-related services(Ads, Analytics, Fonts)</strong>
      </p>
      <p></p>
      <p>
        Our website uses Google Analytics, as an external service provider who
        assists in the independent measurement and audit of the visits of the
        website and other web analytic data on xbodyworld.com.
      </p>
      <p></p>
      <p>
        Google Analytics uses cookies to operate the site’s web analytical
        system.
      </p>
      <p></p>
      <p>
        For further information on Google Analytics’(<a
          href="http://www.google.com/analytics"
          >www.google.com/analytics</a
        >) processing please visit&nbsp;<a
          href="http://www.google.com/intl/hu/policies"
          >http://www.google.com/intl/hu/policies</a
        >.
      </p>
      <p></p>
      <p>
        Document called „How Google uses information from sites or apps that use
        our services” is available at the following link:&nbsp;<a
          href="http://www.google.com/intl/hu/policies/privacy/partners/"
          >http://www.google.com/intl/hu/policies/privacy/partners/</a
        >.
      </p>
      <p></p>
      <p><strong>3.5.4. Facebook</strong></p>
      <p></p>
      <p>
        <strong
          >The data processing activity of the company’s Facebook page</strong
        >
      </p>
      <p></p>
      <p>
        When you are accessing the company’s a Facebook page, we allow Facebook
        to collect cookies at your devices. We inform you that our company does
        not process any personal data on you, we only receive viewing statistics
        for our Facebook page.
      </p>
      <p></p>
      <p>
        Our company only determines the criteria – provided by the Facebook-
        upon which the Facebook provides us the statistical data.
      </p>
      <p></p>
      <p>
        For further information on Facebook’s cookie policy, please visit the
        following link:&nbsp;<a href="https://www.facebook.com/policy/cookies/"
          >https://www.facebook.com/policy/cookies/</a
        >
      </p>
      <p></p>
      <p>
        Our website uses Facebook’s services to provide insights about the
        people who like our Facebook page. The collected data are anonymous to
        the operator of the website, therefore no conclusion about the identity
        of the users can be drawn. However, the data is stored and processed by
        Facebook so that a connection to the uses profile is possible, and
        Facebook can use the data for its own advertising purposes. Our website
        includes plugins for the social network Facebook, Facebook plugins can
        be recognized by the Facebook log or the Like button on our site.
      </p>
      <p></p>
      <p>
        For further information on Facebook’s processing please visit:&nbsp;<a
          href="https://www.facebook.com/about/privacy"
          >https://www.facebook.com/about/privacy</a
        >
      </p>
      <p></p>
      <p>
        Document called „Cookies and other storage technologies” is available at
        the following link:&nbsp;<a
          href="https://www.facebook.com/policies/cookies/"
          >https://www.facebook.com/policies/cookies/</a
        >
      </p>
      <p></p>
      <p>
        If you do not have a Facebook account, you can opt-out of Facebook-based
        advertising on the European Interactive Digital Advertising Alliance
        website:&nbsp;<a
          href="http://www.youronlinechoices.com/uk/your-ad-choices"
          >http://www.youronlinechoices.com/uk/your-ad-choices</a
        >
      </p>
      <p></p>
      <p><strong>3.5.5. Instagram</strong></p>
      <p></p>
      <p>
        Functions of the service Instagram are integrated on our website, for
        further information on Instagram’s processing, please visit:&nbsp;<a
          href="https://help.instagram.com/519522125107875"
          >https://help.instagram.com/519522125107875</a
        >.
      </p>
      <p></p>
      <p>
        Document called „Cookies and other storage technologies” is available at
        the following link:&nbsp;<a
          href="https://www.facebook.com/policies/cookies/"
          >https://www.facebook.com/policies/cookies/</a
        >.
      </p>
      <p></p>
      <p><strong>3.5.6. Youtube</strong></p>
      <p></p>
      <p>
        Our website uses plugins from the Google-powered YouTube page. When you
        visit our YouTube plug-in enabled site, you will be connected to the
        servers of YouTube, which tells the YouTube server which of our you have
        visited. The use of YouTube is in the interest of an attractive
        presentation of our product, services and website.
      </p>
      <p></p>
      <p>
        For further information on YouTube’s processing, please visit please
        visit:&nbsp;<a href="https://policies.google.com/privacy?hl=en"
          >https://policies.google.com/privacy?hl=en</a
        >
      </p>
      <p></p>
      <p>
        Cookie policy is available at the following link:&nbsp;<a
          href="https://policies.google.com/technologies/cookies?hl=en"
          >https://policies.google.com/technologies/cookies?hl=en</a
        >
      </p>
      <p></p>
      <p><strong>3.5.7. Mouseflow</strong></p>
      <p></p>
      <p>
        This website uses Mouseflow: a website analytics tool that provides
        session replay, heatmaps, funnels, form analytics, feedback campaigns,
        and similar features/functionality. Mouseflow may record your clicks,
        mouse movements, scrolling, form fills (keystrokes) in non-excluded
        fields, pages visited and content, time on site, browser, operating
        system, device type (desktop/tablet/phone), screen resolution, visitor
        type (first time/returning), referrer, anonymized IP address, location
        (city/country), language, and similar meta data. Mouseflow does not
        collect any information on pages where it is not installed, nor does it
        track or collect information outside your web browser. If you’d like to
        opt-out, you can do so at&nbsp;<a href="https://mouseflow.com/opt-out"
          >https://mouseflow.com/opt-out</a
        >. If you’d like to obtain a copy of your data, make a correction, or
        have it erased, please contact us first or, as a secondary option,
        contact Mouseflow at&nbsp;<a href="mailto:privacy@mouseflow.com"
          >privacy@mouseflow.com</a
        >.
      </p>
      <p></p>
      <p>
        For more information, see Mouseflow’s Privacy Policy at&nbsp;<a
          href="http://mouseflow.com/privacy/"
          >http://mouseflow.com/privacy/</a
        >.
      </p>
      <p></p>
      <p>
        For more information on Mouseflow and GDPR, visit&nbsp;<a
          href="https://mouseflow.com/gdpr/"
          >https://mouseflow.com/gdpr/</a
        >.
      </p>
      <p></p>
      <p><strong>3.5.8. WordPress</strong></p>
      <p></p>
      <p>
        The Website was created with WordPress, for more information
        see:&nbsp;<a href="https://automattic.com/privacy-notice/"
          >https://automattic.com/privacy-notice/</a
        >.
      </p>
      <p><span style="font-weight: 700">3.5.9. Elementor</span></p>
      <p></p>
      <p>
        The Website was created with Elementor, for more information
        see:&nbsp;<span style="text-align: left"
          ><font color="#2b353a"
            ><a href="https://elementor.com/terms/cookie-policy/"
              >https://elementor.com/terms/cookie-policy/</a
            >.</font
          ></span
        >
      </p>
      <p><span style="font-weight: 700">3.5.10. LinkedIn</span></p>
      <p></p>
      <p>
        Functions of the service LinkedIn are integrated on our website, for
        further information on LinkedIn processing, please visit:&nbsp;<a
          href="https://www.linkedin.com/legal/l/cookie-table"
          >https://www.linkedin.com/legal/l/cookie-table</a
        >.
      </p>
      <p>
        <span style="font-weight: 700">3.5.11. Cookie Consent Manager</span>
      </p>
      <p></p>
      <p>
        Functions of the service Cookie Consent Manager are integrated on our
        website, for further information on CCM’s processing, please
        visit:&nbsp;<a
          href="https://help.consentmanager.net/books/cmp/page/cookies-set-by-the-cmp"
          >https://help.consentmanager.net/books/cmp/page/cookies-set-by-the-cmp</a
        >.
      </p>
      <p></p>
      <h4>3.6. Other processing</h4>
      <p></p>
      <p>
        We provide information about processing that is not listed in this
        Privacy Policy at the time when personal data are obtained. We inform
        our partners and clients that courts, prosecution, investigating
        authorities, the authority dealing with administrative offences,
        administrative authorities, the National Data Protection and Freedom of
        Information Authority and other organs under the provisions of the
        applicable law may contact XBody International in order to receive
        notification or that XBody International discloses, transfers personal
        data or provides documents to them.
      </p>
      <p></p>
      <p>
        XBody International will only provide information to the authorities -if
        they have indicated the exact purpose and scope of the personal data –
        that is&nbsp;<strong
          >strictly necessary to the purpose of the inquiry</strong
        >.
      </p>
      <p></p>
      <h3>4. METHOD OF STORING PERSONAL DATA, SECURITY OF PROCESSING</h3>
      <p></p>
      <p>
        We and our processors by taking into account the state of the
        technology, the costs of the execution, the nature, scope, context and
        purposes of processing as well as the risks of varying likelihood and
        severity for the rights and freedoms of natural persons,&nbsp;<strong
          >implement appropriate technical and organisational measures to ensure
          the appropriate level of data protection</strong
        >.
      </p>
      <p></p>
      <p>
        We choose and operate IT tools for data processing so that the processed
        personal data:
      </p>
      <p></p>
      <ul>
        <li>
          is accessible to the authorised person
          (<strong>availability</strong>);
        </li>
        <li>
          its authenticity and authentication is ensured (<strong
            >credibility of processing</strong
          >);
        </li>
        <li>
          can be verified that there is no change in the data (<strong
            >data integrity</strong
          >);
        </li>
        <li>
          is protected against unauthorised or unlawful access (<strong
            >confidentiality of data</strong
          >).
        </li>
      </ul>
      <p>
        We protect your data with appropriate measures against unlawful access,
        alteration, transfer, disclosure, erasure or destruction, as well as
        accidental loss, damage, and the accidental unavailability due to the
        change of the used technic.
      </p>
      <p></p>
      <p>
        In order to protect the electronic processed personal data that are
        stored in our various registries, we provide appropriate technical
        measures to ensure that the stored personal data – unless it is
        permitted by law –&nbsp;<strong
          >cannot be directly linked and assigned to the data subjects</strong
        >.
      </p>
      <p></p>
      <p>
        The provided technical and organisational measures are in accordance
        with the current state of technology and they are compatible with the
        level of risks associated to ensure the security of processing.
      </p>
      <p></p>
      <p>During processing we respect and maintain</p>
      <p></p>
      <ul>
        <li>
          <strong>confidentiality:</strong>&nbsp;protecting the information so
          that it can only be accessed by those who are entitled to it;
        </li>
        <li>
          <strong>integrity:</strong>&nbsp;protecting the accuracy and
          completeness of information and processing methods;
        </li>
        <li>
          <strong>availability:</strong>&nbsp;ensuring that when the data
          subject needs it, he/she can actually access the information and
          she/he has procedures for it.
        </li>
      </ul>
      <p>
        XBody International and our partners’ IT systems are protected against
        computer-aided fraud, espionage, sabotage, vandalism, fire and flood, as
        well as computer viruses, computer intrusion and denial-of-service
        attacks. The operator provides security through server-level and
        application-level security procedures. We inform you that electronic
        mails apart from the protocols (email, web, ftp etc.) that are
        transferred through the Internet are vulnerable to network threats that
        may lead to fraudulent activity, challenge of the contract and
        disclosure or modification of information. We shall take all the
        precautionary measures to protect you from such threats. Systems are
        monitored so that we could record all security dangers and provide
        evidence of any security incident. System monitoring also allows the
        controlling of the efficiency of the precautionary measures.
      </p>
      <p></p>
      <p>
        We, as data controller record potential personal data breaches,
        including facts and effects of the personal data breach and measures
        taken or proposed to be taken by us to address the personal data breach.
        We&nbsp;<strong
          >shall notify the National Data Protection and Freedom of Information
          Authority</strong
        >&nbsp;on any potential personal data breach without undue delay and,
        where feasible,&nbsp;<strong>not later than 72 hours</strong>&nbsp;after
        having become aware of it, unless the personal data breach is unlikely
        to result in a risk to the rights and freedoms of natural persons.
      </p>
      <p></p>
      <p>
        Your password protects your account, so we encourage you to use a unique
        and strong password, limit access to your computer and browser, and log
        out after you have finished using the XBody Partner Portal.
      </p>
      <p></p>
      <h3>5. RIGHTS OF THE DATA SUBJECT</h3>
      <p></p>
      <p>
        We inform you that in relation to the processing of your personal data
        you dispose the following rights:
      </p>
      <p></p>
      <ul>
        <li>Transparent information,</li>
        <li>Right of access by the data subject,</li>
        <li>Right to rectification,</li>
        <li>Right to erasure (‘right to be forgotten’),</li>
        <li>Right to restriction of processing,</li>
        <li>Right to data portability,</li>
        <li>Right to object,</li>
        <li>Right to withdrawal of consent,</li>
        <li>Right to effective remedy.</li>
      </ul>
      <h4>5.1. Transparent information</h4>
      <p></p>
      <p>
        We are obliged to provide you with information – in accordance with the
        provisions of the GDPR – about the processing of your personal data. We
        fulfil our obligation by providing the present Privacy Policy to you.
        Please be aware of the fact, that if you have further questions in
        relation to the processing of your personal data you can exercise your
        right of access.
      </p>
      <p></p>
      <h4>5.2. Right of access by the data subject</h4>
      <p></p>
      <p>
        You have the right to get information whether we process your personal
        data and if so, you may demand a detailed prospectus that contains the
        following information:
      </p>
      <p></p>
      <ul>
        <li>the reason of the data processing,</li>
        <li>the scope of the processed personal data,</li>
        <li>the source of the processed data,</li>
        <li>the recipient of the data,</li>
        <li>the term of the storage of the processed personal data,</li>
        <li>
          in case of an international transfer of your personal data, the
          guarantees of the transfer, and
        </li>
        <li>
          a repeated information on your rights in relation to the processing of
          your personal data and your right to lodge a complaint with a
          supervisory authority.
        </li>
      </ul>
      <p>
        For your request we may give you a copy of your processed personal
        data&nbsp;<strong>free of charge</strong>.
      </p>
      <p></p>
      <p>
        For further copies we may charge you with a reasonable fee taking into
        account the administrative costs of providing such information.2 We
        provide information by electronic means for your request. You may
        exercise your right of access in writing at the following contact
        details:
      </p>
      <p></p>
      <ul>
        <li>
          e-mail address:&nbsp;<a href="mailto:helpdesk@xbodyworld.com"
            >helpdesk@xbodyworld.com</a
          >
        </li>
        <li>phone number: +36 96&nbsp;200 180</li>
      </ul>
      <p>
        Upon your request, the aforementioned information may be provided orally
        if your identity is proven genuinely.
      </p>
      <p></p>
      <h4>5.3. Right to rectification</h4>
      <p></p>
      <p>
        For your request&nbsp;<strong
          >we rectify, correct or complete your inaccurate personal data</strong
        >&nbsp;(e.g. your date of birth is inaccurate, your e-mail address or
        your name has changed).
      </p>
      <p></p>
      <h4>5.4. Right to erasure</h4>
      <p></p>
      <p>
        You may request that we erase your processed personal data without undue
        delay if,
      </p>
      <p></p>
      <ul>
        <li>
          the purpose of the data processing no longer justifies the processing,
        </li>
        <li>
          consent is the legal basis of the processing and you have withdrawn
          your consent (and there is no other applicable legal basis),
        </li>
        <li>
          you object the processing and there are no overriding legitimate
          grounds for the processing,
        </li>
        <li>the personal data have been unlawfully processed, or</li>
        <li>the personal data have to be erased under legal obligation.</li>
      </ul>
      <p>
        The right of erasure cannot be exercised if the processing
        is&nbsp;<strong
          >necessary for exercising the right of freedom of expression and
          information</strong
        >, the compliance with a&nbsp;<strong>legal obligation</strong
        >&nbsp;that XBody International is subject to, or for the&nbsp;<strong
          >performance of a public task</strong
        >, or reasons of public interest in the area of public health or
        archiving purposes in the public interest, scientific or historical
        research purposes or statistical purposes, or for the establishment,
        exercise or defence of legal claims.
      </p>
      <p></p>
      <h4>5.5. Right to restriction of processing</h4>
      <p></p>
      <p>
        If you exercise this right and according to the applicable law you shall
        obtain restriction of processing, we cannot process your personal data
        (therefore we cannot transfer or organise your data) –&nbsp;<strong
          >with the exception of storage</strong
        >&nbsp;– unless you consent or it is necessary for the establishment,
        exercise or defence of XBody International’s legal claims.
      </p>
      <p></p>
      <p>When can you exercise this right?</p>
      <p></p>
      <ul>
        <li>
          If you claim that your personal data is inaccurate, for a period while
          we examine the accuracy of your data.
        </li>
        <li>
          If the processing is unlawful but instead of erasure, you request the
          restriction of your data.
        </li>
        <li>
          If we no longer need your personal data (for the purpose of the
          processing) however you request that we store them (e.g. for the
          establishment, exercise or defence of legal claims).
        </li>
        <li>
          If you object the processing, for a period while we verify whether our
          legitimate grounds override those of yours.
        </li>
      </ul>
      <p>
        We inform you before the restriction of the processing is lifted (e.g.
        your inaccurate data have been rectified or we declined your request of
        restriction).
      </p>
      <p></p>
      <h4>5.6. Right to data portability</h4>
      <p></p>
      <p>
        If the legal basis of the processing is
        your&nbsp;<strong>consent</strong>&nbsp;or the&nbsp;<strong
          >performance of a contract</strong
        >&nbsp;and the processing is carried out&nbsp;<strong
          >by automated means you</strong
        >&nbsp;may request to receive the personal data that concerns you and
        which you provided us, in a structured, commonly used and
        machine-readable format You may also request that we transmit your data
        to another controller.
      </p>
      <p></p>
      <p>
        We inform you that we do not process your personal data by automated
        means.
      </p>
      <p></p>
      <h4>5.7. Right to object</h4>
      <p></p>
      <p>
        You may object on the grounds&nbsp;<strong
          >relating to your particular situation</strong
        >, at&nbsp;<strong>any time</strong>&nbsp;to the processing of personal
        data concerning you if the legal basis of the processing is the
        legitimate interest of XBody International In this case, we will no
        longer process your personal data unless we demonstrate compelling
        legitimate grounds for the processing which override your interests,
        rights and freedoms or for the establishment, exercise or defence of
        legal claims.
      </p>
      <p></p>
      <p>
        If we process your personal data for&nbsp;<strong
          >direct marketing purposes</strong
        >&nbsp;(e.g. newsletter) you can object the processing of your personal
        data concerning you&nbsp;<strong>at any time</strong>. We will no longer
        process your personal data if you object the processing.
      </p>
      <p></p>
      <p><strong>5.8. Right to withdraw a consent</strong></p>
      <p></p>
      <p>
        You have the right to&nbsp;<strong
          >withdraw your consent at any time</strong
        >&nbsp;during the processing.
      </p>
      <p></p>
      <p>
        We inform you that the withdrawal of your consent shall not affect the
        lawfulness of processing based on your consent before its withdrawal.
      </p>
      <p>You can exercise your right by the following methods:</p>
      <p></p>
      <ul>
        <li>
          <strong
            >You can unsubscribe from our legal newsletter by clicking to the
            following link:</strong
          ><br />You can unsubscribe from our newsletter by clicking to the
          unsubscribe button provided in our e-mail.
        </li>
        <li>
          <strong
            >Or you can notify us at the following e-mail address that you would
            like to withdraw your consent:</strong
          ><br /><a href="mailto:helpdesk@xbodyworld.com"
            >helpdesk@xbodyworld.com</a
          >
        </li>
      </ul>
      <h4>5.9. Procedural provisions</h4>
      <p></p>
      <p>
        Please note that we will notify you on the action taken without undue
        delay, but within&nbsp;<strong
          >one month of receipt of your request</strong
        >. We may&nbsp;<strong>extend</strong>&nbsp;the period by&nbsp;<strong
          >further two months</strong
        >&nbsp;if it is necessary due to the complexity and the number of the
        requests. We inform you on the extension within one month of the receipt
        of your request.
      </p>
      <p></p>
      <p>
        If you submit your request by electronic means, XBody International will
        give you the information by electronic means (unless you request
        otherwise).
      </p>
      <p></p>
      <p>
        If we do not take any action on your request, we inform you – without
        any undue delay and at the latest within one month of the receipt of
        your request – on its reasons and on the possibility to lodge a
        complaint with the Data Protection Authority and to seek a judicial
        remedy.
      </p>
      <p></p>
      <p>
        We inform you that we provide the requested information&nbsp;<strong
          >free of charge</strong
        >. If your request is clearly unfounded or excessive, in particular
        because of its repetitive character, we can charge you with
        a&nbsp;<strong>reasonable fee for administrative costs</strong>&nbsp;or
        we can refuse to take action on your request.
      </p>
      <p></p>
      <p>
        We communicate any rectification or erasure of personal data or
        rectification of processing to each recipient with whom we have provided
        your personal information (unless it is impossible). Upon your request,
        we inform you about these recipients.
      </p>
      <p></p>
      <h4>5.10. Right to compensation</h4>
      <p></p>
      <p>
        XBody International compensates your damages that we caused by unlawful
        processing or breaching the data protection requirements. In case of the
        violation of the rights relating to your personality, you are entitled
        to restitution in accordance with the provisions of the Civil Code.
      </p>
      <p></p>
      <p>
        We inform you that XBody International is liable for any damages caused
        by a data processor that we used during the processing of your personal
        data. XBody International shall be relieved of liability if the damage
        occurred in consequence of unforeseen circumstances beyond our control.
      </p>
      <p></p>
      <p>
        XBody International does not compensate your damages and you cannot
        claim restitution insofar as damage or impairment – caused by the
        violation of the right relating to your personality – is caused by your
        intentional or gross negligent behaviour.
      </p>
      <p></p>
      <h4>5.11. Remedies</h4>
      <p></p>
      <p><strong>5.11.1. Right to a judicial remedy</strong></p>
      <p></p>
      <p>
        If you consider that your rights under data protection law have been
        infringed (e.g. you couldn’t exercise your aforementioned rights, you
        didn’t receive any information on the processing etc.) you may file for
        court action against the controller before the&nbsp;<strong
          >general court</strong
        >&nbsp;in whose jurisdiction your&nbsp;<strong
          >home address or habitual residence is located</strong
        >. The court shall hear such cases in&nbsp;<strong
          >priority proceedings</strong
        >. We inform you that lawsuits related to personal data protection
        are&nbsp;<strong>free of charge</strong>.
      </p>
      <p></p>
      <p>
        <strong
          >5.11.2. Right to lodge a complaint with a supervisory
          authority</strong
        >
      </p>
      <p></p>
      <p>
        If you consider that your rights under data protection law have been
        infringed you can choose to lodge a complaint with the National Data
        Protection and Freedom of Information Authority.
      </p>
      <p></p>
      <p><a></a>Contact details of the authority:</p>
      <p></p>
      <p>
        Name: National Data Protection and Freedom of Information Authority
        (“Nemzeti Adatvédelmi és Információszabadság Hatóság” in Hungarian)<br />Seat:
        1055 Budapest, Falk Miksa utca 9-11.<br />Mailing address: 1363
        Budapest, Pf.: 9.
      </p>
      <p></p>
      <h2>INFORMATION ON COOKIES USED BY XBODYWORLD.COM</h2>
      <p></p>
      <h3>1. What are cookies?</h3>
      <p></p>
      <p>
        Cookies are small-sized files containing letters, numbers, punctuation
        and other technical characters that are placed on your device (computer,
        mobile phone, tablet, smart TV, etc.) when you visit a website. These
        are computer data, helping the utilisation of websites, and are sent by
        the web server to the user’s browser, are stored on the user’s device
        and the browser resends them to the server in each case when such
        request arises. They don’t contain executable files, computer viruses,
        spyware and have no access to the data on the user’s hard drive.
      </p>
      <p></p>
      <p>
        Cookies usually contain the following: domain name of the visited
        website (that is the access path inserted in the browser), storage
        duration of the cookie and the stored information. Among others, cookies
        allow the recognition of the device and browser of the user, hence they
        facilitate the display of content tailored to the visitor’s needs and
        basic browsing functions, such as ensuring coherent sessions. Beside
        anonymity of users, cookies support the preparation of website traffic
        statistics.
      </p>
      <p></p>
      <p>
        We don’t store personally identifiable data in cookies, but in order to
        improve user experience, we use encrypted information that stem from
        those cookies. For example, they help us in identifying and eliminating
        errors or in defining the circle of interesting and relevant products
        that we would like to draw your attention to while browsing our website.
      </p>
      <p></p>
      <p>
        In case you still reach out to us via any contact form – with your
        explicit consent – on a website of ours where we use cookies, it may
        occur that we get to know data regarding your website visits following
        the utilisation of the form, with the help of these cookies. This is
        done in favour of the improvement of user experience.
      </p>
      <p></p>
      <p>
        If you accepted the Cookie Information on our websites, it won’t appear
        again unless you remove cookies from the settings of the browser
        (chapter 6 provides assistance for this).
      </p>
      <p></p>
      <p>
        If you share any content of xbodyworld.com on a social media site – e.g.
        Facebook, YouTube, Instagram –, then you may receive further cookies
        from these sites. These cookies are not controlled by the
        xbodyworld.com, therefore we suggest you visiting the third party’s site
        for further information on these cookies and relating to their control.
      </p>
      <p></p>
      <h3>2. Categories of cookies used on the website</h3>
      <p></p>
      <h4>2.1. Session cookies</h4>
      <p></p>
      <p>
        There are cookies that are indispensable for the proper operation of the
        website and that of its functions and for the navigation on the website.
        In order to facilitate customized service and login process, the system
        identifies the computer of the logged-in User with „session cookie”. If
        User closes only the browser window, the logged-in status remains active
        when he/she reopens the window. These cookies don’t collect personally
        identifiable information (personal data) on the User or information that
        could be used for marketing purposes or would remember the User’s prior
        visits on other websites or subpages of a website.
      </p>
      <p></p>
      <h4>2.2. Behavioural tracking cookies</h4>
      <p></p>
      <p>
        In order that we could provide personalized service for the User, offer
        jobs/job advertisements that best match their interest and needs,
        improve the efficiency of service, analyse their website visits and
        utilization (the fact of visiting and duration of visiting the website
        and its subpages, monitoring of applications), we place cookies on your
        browser, hence on your computer, phone or on other device (end user
        device) and we use them, if User gives its prior explicit consent to it.
        User may give this consent by clicking the button „I accept” on the text
        track appearing on the website. Behavioural tracking cookie stores a
        randomly generated identifier, then, based on this, the application
        records certain user events (application, viewing advertisements,
        duration of visit, key word, location, type of device) in the data base.
        After logging in the website, it connects data recorded on the behaviour
        of User to User.
      </p>
      <p></p>
      <h4>2.3. „Third party” cookies</h4>
      <p></p>
      <p>
        Third-party cookies are downloaded by User’s browser when visiting a
        page, however not from the Company’s (sub)domain, but from the domain of
        a third party (typically an ad mediator or Facebook), given the fact
        that the website constitutes partly of these Third-party modules.
        Third-party cookies are back to the third party, for example when User
        visits one of their advertisements or visits their website. With the
        help of these cookies, the third party (such as an advertiser) may track
        User’s full browsing history in case of those websites that contain the
        third party’s so-called Third-party modules (e.g. its advertisement
        module and advertisements).
      </p>
      <p></p>
      <h3>3. Stored cookies</h3>
      <p></p>
      <h4>3.1. Own cookies</h4>
      <p></p>
      <table>
        <thead>
          <tr>
            <th>Name of the cookie</th>
            <th>Purpose of the cookie</th>
            <th>Storing time of the cookie</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>PHPSESSID</td>
            <td>Functional cookie</td>
            <td>Session</td>
          </tr>
        </tbody>
      </table>
      <h4>3.2. Behavioural tracking cookie</h4>
      <p></p>
      <table>
        <thead>
          <tr>
            <th>Name of the cookie</th>
            <th>Service provider</th>
            <th>Description of cookie</th>
            <th>Storing time of the cookie</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>_ga</td>
            <td>Google</td>
            <td>Statistics</td>
            <td>2 years</td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>Google</td>
            <td>Statistics</td>
            <td>Session</td>
          </tr>
        </tbody>
      </table>
      <h4>3.3. Third Party cookie</h4>
      <p></p>
      <table>
        <thead>
          <tr>
            <th>Name of the cookie</th>
            <th>Service provider</th>
            <th>Description of cookie</th>
            <th>Storing time of the cookie</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>_dc_gtm_UA-#</td>
            <td>Google</td>
            <td>Functional cookie</td>
            <td>Session</td>
          </tr>
          <tr>
            <td>fr</td>
            <td>Facebook</td>
            <td>Advertisement</td>
            <td>3 months</td>
          </tr>
          <tr>
            <td>GPS</td>
            <td>YouTube</td>
            <td>Statistics</td>
            <td>Session</td>
          </tr>
          <tr>
            <td>VISITOR_INFO1_LIVE</td>
            <td>YouTube</td>
            <td>Functional cookie</td>
            <td>179 days</td>
          </tr>
          <tr>
            <td>YSC</td>
            <td>YouTube</td>
            <td>Statistics</td>
            <td>Session</td>
          </tr>
        </tbody>
      </table>
      <h3>4. Data processors</h3>
      <p></p>
      <p><strong>Data of the cloud hosting provider:</strong></p>
      <p></p>
      <p>
        Microsoft Ireland Operations, Ltd.<br />One Microsoft Place<br />South
        County Business Park, Leopardstown, Dublin 18, D18 P521
      </p>
      <p></p>
      <p>
        KingSol Informatikai Szolgáltató és Tanácsadó Kft.<br />Seat: 1084
        Budapest, Tolnai Lajos utca 18 3. em 4.<br />Company reg. number:
        01-09-948538<br />Tax number: 23004122-2-42
      </p>
      <p></p>
      <p><strong>Developer of the website:</strong></p>
      <p></p>
      <p>
        WebOrigo Magyarország Zrt.<br />Seat:&nbsp;<span
          style="text-align: left"
          >9. fszt., Bem József street, 1027 Budapest, Hungary</span
        ><br />Company reg. number: 01-10-141001<br />Tax number:&nbsp;<span
          style="
            color: rgb(68, 68, 68);
            font-family: 'Open Sans', sans-serif;
            text-align: start;
          "
          >28767116</span
        >-2-41<br />Website:&nbsp;<a
          href="https://weborigo.hu"
          target="_blank"
          style="
            font-family: var(--e-global-typography-text-font-family), Sans-serif;
            font-weight: var(--e-global-typography-text-font-weight);
            background-color: rgb(255, 255, 255);
            text-align: left;
          "
          >weborigo.hu</a
        >
      </p>
      <p></p>
      <p><strong>YouTube:</strong></p>
      <p></p>
      <p>
        YouTube, LLC<br />901 Cherry Ave.<br />San Bruno,&nbsp;CA&nbsp;94066<br />USA
      </p>
      <p></p>
      <p><strong>Google:</strong></p>
      <p></p>
      <p>
        Google Ireland Limited – Google Analytics<br />Gordon House<br />Barrow
        Street, Dublin 4<br />Ireland
      </p>
      <p></p>
      <p><strong>Facebook:</strong></p>
      <p></p>
      <p>
        Facebook Ireland Ltd.<br />4 Grand Canal Square<br />Grand Canal
        Harbour, Dublin 2<br />Ireland
      </p>
      <p></p>
      <h3>5. Transmission to third countries (outside the EU)</h3>
      <p></p>
      <table>
        <thead>
          <tr>
            <th>Through which data processor</th>
            <th>Where</th>
            <th>Guarantees of transmission</th>
            <th>Reason of transmission to third country</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>YouTube</td>
            <td>USA</td>
            <td>
              Privacy Shield&nbsp;<a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000TN8pAAG&amp;status=Active"
                >https://www.privacyshield.gov/participant?id=a2zt0000000TN8pAAG&amp;status=Active</a
              >
            </td>
            <td>Data storing of video- sharing system</td>
          </tr>
          <tr>
            <td>Google</td>
            <td>USA</td>
            <td>
              Privacy Shield&nbsp;<a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000TN8pAAG&amp;status=Active"
                >https://www.privacyshield.gov/participant?id=a2zt0000000TN8pAAG&amp;status=Active</a
              >
            </td>
            <td>Data relating to statistical and user habits</td>
          </tr>
        </tbody>
      </table>
      <h3>6. Handling of cookies</h3>
      <p></p>
      <p>
        Visitors of the page may remove and ban cookies used by the Company’s
        web page, as well as they may modify settings.
      </p>
      <p></p>
      <p>
        In case of different browsers, instructions are available on the
        following links regarding the modification of settings:
      </p>
      <p></p>
      <ul>
        <li>
          <strong>Google Chrome</strong><br /><a
            href="https://support.google.com/chrome/answer/95647?hl=hu&amp;co=GENIE.Platform%3DDesktop"
            >https://support.google.com/chrome/answer/95647?hl=hu&amp;co=GENIE.Platform%3DDesktop</a
          >
        </li>
        <li>
          <strong>Microsoft Internet Explorer and Edge</strong><br /><a
            href="https://support.microsoft.com/hu-hu/help/17442/windows-internet-explorer-delete-manage-cookies"
            >https://support.microsoft.com/hu-hu/help/17442/windows-internet-explorer-delete-manage-cookies</a
          >
        </li>
        <li>
          <strong>Mozilla Firefox</strong><br /><a
            href="https://support.mozilla.org/hu/kb/sutik-engedelyezese-es-tiltasa-amit-weboldak-haszn"
            >https://support.mozilla.org/hu/kb/sutik-engedelyezese-es-tiltasa-amit-weboldak-haszn</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import NavbarComponent from '@/components/NavbarComponent';
export default {
  name: 'PolicyView',
  components: {
    NavbarComponent,
  },
};
</script>
<style lang="css" src="./Policy.css" scoped></style>
