<template>
  <div class="reset">
    <navbar-component class="justify-center" />
    <div class="reset-wrapper d-flex">
      <div class="reset-left">
        <div class="img-wrapper">
          <img src="~@/assets/imgs/login.svg" alt="side img" />
        </div>
      </div>
      <div class="reset-rigth">
        <h2 class="reset-title">{{ $t('resetPassword') }}</h2>
        <p class="reset-subtitle semi-16 color-gray-cool">
          {{ $t('enterNewPassword') }}
        </p>
        <form class="form" @submit.prevent="resetPassword">
          <!-- <div class="form__input">
            <label class="semi-16 color-black">
              {{ $t('emailAddress') }}
            </label>
            <input
              type="email"
              class="color-gray-warm"
              v-model="postObject.email"
              :placeholder="$t('emailAddress')"
            />
          </div> -->
          <div class="form__input">
            <label class="semi-16 color-black">
              {{ $t('newPassword') }}
            </label>
            <input
              type="password"
              class="color-gray-warm"
              v-model="postObject.password"
              :placeholder="$t('newPassword')"
            />
          </div>
          <div class="form__input">
            <label
              class="d-flex align-center justify-space semi-16 color-black"
            >
              {{ $t('repeat') }} {{ $t('newPassword') }}
            </label>
            <input
              type="password"
              class="color-gray-warm"
              v-model="postObject.newPassword"
              :placeholder="$t('repeat') + ' ' + $t('newPassword')"
            />

            <p v-if="isSamePassword" class="red">{{ isSamePassword }}</p>
          </div>
          <button class="form__submit semi-16 color-white" type="submit">
            {{ $t('confirmNewPassword') }}
          </button>
          <div class="auth-language">
            <LanguageSelector />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent';
import LanguageSelector from '@/components/LanguageSelector/LanguageSelector.vue';
import api from '@/api';
export default {
  name: 'ResetPassword',
  components: {
    NavbarComponent,
    LanguageSelector,
  },
  data() {
    return {
      userData: {
        password: '',
        passwordConfirmation: '',
        token: '',
        email: '',
      },
      isEmail: true,
      isSamePassword: null,
      postObject: {
        email: null,
        password: null,
        newPassword: null,
      },
    };
  },
  created() {
    const queryString = window.location.search;
    const urlParamsArray = [...new URLSearchParams(queryString).keys()];
    if (urlParamsArray.length === 0) {
      this.$notify({
        title: 'Warning',
        text: 'No access',
        type: 'warn',
        duration: 1000,
        speed: 1000,
      });
      this.$router.push({ name: 'Login' });
    }
  },
  mounted() {
    this.getSearchParams();
  },
  methods: {
    async resetPassword() {
      if (this.postObject.password != this.postObject.newPassword) {
        this.isSamePassword = this.$t('passwordsDoNotMatch');
        return;
      } else {
        this.isSamePassword = null;
      }
      const postBody = {
        email: this.userData.email,
        token: this.userData.token,
        password: this.postObject.password,
        password_confirmation: this.postObject.newPassword,
      };
      await api.getCookie();
      const res = await api.resetPassword(postBody);
      console.log(res);
      const { data } = res;
      const { messages, code } = data;
      if (code === 422) {
        const { error } = messages;
        this.$toast.error(error, this.toastOption);
        return;
      }
      const { general } = messages;
      this.$toast.success(general, this.toastOption);
      this.$router.push({ name: 'Login' });
    },
    getSearchParams() {
      const keys = ['token', 'email'];
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      keys.forEach((key) => {
        this.userData[key] = urlParams.get(key);
      });
    },
  },
};
</script>

<style lang="scss" src="./ResetPasswordView.scss" scoped></style>
