<template>
  <div class="sidebar-wrapper" v-if="!isMobile">
    <aside class="sidebar">
      <ul class="sidebar-list">
        <router-link
          v-for="link in links"
          :key="link.link"
          tag="li"
          custom
          :to="link.link"
          class="d-flex align-center pointer"
          :class="{ visible: isOpen }"
        >
          <div class="d-flex align-center medium-16 color-black-light ml-3">
            <span
              :class="`icon icon-${link.icon} mr-10`"
              :title="link.name"
            ></span>
          </div>
        </router-link>
      </ul>
    </aside>
  </div>
</template>

<script>
export default {
  name: 'SideBarIconsComponent',
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      links: [
        {
          name: this.$t('settings'),
          link: '/settings',
          icon: 'settings',
        },
        {
          name: this.$t('manageConsents'),
          link: '/manage',
          icon: 'lock',
        },
        {
          name: this.$t('checkActiveApplications'),
          link: '/check-active',
          icon: 'check',
        },
        {
          name: this.$t('joinSocialAccounts'),
          link: '/social-accounts',
          icon: 'join',
        },
        {
          name: this.$t('invoices'),
          link: '/invoices',
          icon: 'dollar',
        },
      ],
      isMobile: false,
    };
  },
  methods: {
    onResize() {
      this.isMobile = document.body.clientWidth <= 768;
    },
  },
  mounted() {
    this.onResize();
    window.onresize = this.onResize;
  },
};
</script>

<style lang="scss" src="../SidebarComponent/SidebarComponent.scss"></style>
<style scoped>
.sidebar-wrapper {
  width: 60px !important;
  border-right: 1px solid #f5f5f5 !important;
}
.visible {
  opacity: 1;
  padding-left: 0px;
}
</style>
