<template>
  <div class="check-wrapper page page-padding">
    <modal-component :show="false">
      <template #close-btn>
        <div class="modal-close" @click="closeModal"></div>
      </template>
      <template #modal-body>
        <div class="check-input">
          <label class="medium-14 color-gray-dark">{{ $t('name') }}</label>
          <input class="color-gray-warm medium-14" :placeholder="$t('name')" />
        </div>
        <div class="check-input check-input-short">
          <label class="medium-14 color-gray-dark">{{ $t('date') }}</label>
          <date-picker
            v-model="time1"
            valueType="format"
            :placeholder="$t('date')"
          ></date-picker>
        </div>
        <div class="check-input">
          <label class="medium-14 color-gray-dark">{{ $t('nameOfApp') }}</label>
          <!-- <dropdown-component
            :items="appNames"
            v-model="appName"
            :placeholder="$t('nameOfApp')"
          /> -->
        </div>
        <div class="check-input">
          <label class="medium-14 color-gray-dark">{{ $t('status') }}</label>
          <!-- <dropdown-component
            :items="items"
            v-model="status"
            :placeholder="$t('status')"
          /> -->
        </div>
      </template>
      <template #footer>
        <div class="modal-footer mt-40 d-flex aling-center justify-end button">
          <button type="submit" class="semi-18 color-white" @click="closeModal">
            {{ $t('save') }} <span class="icon icon-save ml-20"></span>
          </button>
        </div>
      </template>
    </modal-component>
    <table-component title="Manage active applications" :filters="false">
      <template #header>
        <thead class="table-header">
          <tr>
            <th
              v-for="(header, index) in tableHeader"
              :key="index"
              class="semi-18 color-gray-cool"
              :class="`text-align-${header.aling}`"
              :colspan="header.span ? header.span : 1"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
      </template>
      <template #data>
        <tbody class="table-body">
          <tr v-for="data in tableData" :key="data.id">
            <td class="medium-20 color-gray-dark text-align-left">
              {{ data.date }}
            </td>
            <td class="medium-20 color-gray-dark text-align-left">
              {{ data.name }}
            </td>
            <td class="medium-20 color-gray-dark">
              <img v-if="data.image" class="w-150" :src="data.image" alt="" />
            </td>
            <td class="medium-20 color-gray-dark text-align-left">
              <!-- <span>{{ data.appName }}</span> -->
            </td>
            <td class="text-align-left">
              <chip-component
                :text="data.status.text"
                :class="`color-${data.status.color}`"
              />
            </td>
            <!-- <td>
              <div class="table-edit d-flex-inline align-center justify-center">
                <span
                  class="icon icon-edit"
                  :class="[
                    data.status.value == 'pending'
                      ? 'icon-edit'
                      : 'icon-edit-1',
                  ]"
                  @click="openModal"
                ></span>
              </div>
            </td> -->
          </tr>
        </tbody>
      </template>
    </table-component>
  </div>
</template>

<script>
import TableComponent from '@/components/TableComponent';
import ChipComponent from '@/components/ChipComponent';
import ModalComponent from '@/components/ModalComponent';
// import DropdownComponent from '@/components/DropdownComponent';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import api from '@/api';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ChechActiveView',
  components: {
    TableComponent,
    ChipComponent,
    ModalComponent,
    DatePicker,
    // DropdownComponent,
  },
  data() {
    return {
      tableHeader: [
        {
          text: this.$t('date'),
          aling: 'left',
        },
        {
          text: this.$t('name'),
          aling: 'left',
        },
        {
          text: this.$t('nameOfApplication'),
          aling: 'center',
          span: 2,
        },
        {
          text: this.$t('condition'),
          aling: 'left',
        },
        // {
        //   text: this.$t('edit'),
        //   aling: 'center',
        // },
      ],
      tableData: [],
      showModal: false,
      time1: null,
      arrayOfObjects: [
        {
          name: 'Object Name 2',
          test: 'test 2',
        },
        {
          name: 'Object Name 3',
          test: 'test 3',
        },
        {
          name: 'Object Name 4',
          test: 'test 4',
        },
        {
          name: 'Object Name 5',
          test: 'test 5',
        },
      ],
      status: null,
      boolean: true,
      items: [
        {
          text: this.$t('pending'),
          value: 'pending',
          color: 'warm-grey',
        },
        {
          text: this.$t('active'),
          value: 'active',
          color: 'green',
        },
        {
          text: this.$t('inactive'),
          value: 'inactive',
          color: 'red',
        },
      ],
      object: {
        name: 'Object Name',
        test: 'test',
      },
      appNames: [
        {
          name: 'App Name 1',
          value: 'app1',
        },
        {
          name: 'App Name 2',
          value: 'app2',
        },
      ],
      appName: null,
    };
  },
  computed: {
    ...mapGetters(['userData']),
  },
  methods: {
    ...mapActions(['updateShowLoader']),
    methodToRunOnSelect(payload) {
      this.object = payload;
    },
    // openModal() {
    //   this.showModal = true;
    // },
    // closeModal() {
    //   this.showModal = false;
    // },
    // dateParser(dateString) {
    //   const currentDate = new Date(dateString);
    //   const year = currentDate.getFullYear();
    //   const day =
    //     currentDate.getDay() < 10
    //       ? `0${currentDate.getDay()}`
    //       : currentDate.getDay();
    //   const month =
    //     currentDate.getMonth() < 10
    //       ? `0${currentDate.getMonth()}`
    //       : currentDate.getMonth();
    //   return `${day}/${month}/${year}`;
    // },
    async getServices() {
      const { data } = await api.getServices();
      this.tableData = data.data.map((item) => {
        let check = this.checkServiceExistence(item.name);
        return {
          date: this.serviceDate(item.name),
          id: item.id,
          name: this.userData.name,
          status: check
            ? this.checkServicePending(item.name)
              ? this.items[0]
              : this.items[1]
            : this.items[2],
          appName: item.name,
          image: this.serviceImage(item.name),
        };
      });
    },
    checkServiceExistence(name) {
      let exist = false;
      this.userData.services.forEach((service) => {
        if (service.name == name) {
          exist = true;
        }
      });
      return exist;
    },
    checkServicePending(name) {
      let pending = true;
      this.userData.services.forEach((service) => {
        if (
          service.name == name &&
          (service.service_status == 'yes' ||
            service.service_status == 'registration_confirmed')
        ) {
          pending = false;
        }
      });
      return pending;
    },
    serviceDate(name) {
      let date = '-';
      this.userData.services.forEach((service) => {
        if (service.name == name) {
          date = service.date;
        }
      });
      return date;
    },

    serviceImage(name) {
      let image = null;
      switch (name) {
        case 'studion':
          image = 'imgs/xbody-asset-4.svg';
          break;
        case 'xbody-id-portal':
          image = 'imgs/xbody.png';
          break;

        case 'lms-portal':
          image = 'imgs/xbody-asset-1.svg';
          break;
        case 'fit-portal':
          image = 'imgs/xbody-asset-3.svg';
          break;
        case 'asset-portal':
          image = 'imgs/xbody-asset.svg';
          break;
      }
      return image;
    },
  },
  async mounted() {
    await this.getServices();
    this.updateShowLoader(false);
  },
};
</script>

<style lang="scss" src="./ChechActiveView.scss" scoped></style>
