<template>
  <div class="settings-wrapper page">
    <modal-component :show="showNoBillingInformationModal">
      <template #close-btn>
        <div
          class="modal-close"
          @click="closeModal('billingInformation')"
        ></div>
      </template>
      <template #modal-body>
        <p style="text-align: center">
          to continue your shopping in our platforms
        </p>
        <div
          style="
            margin: 10px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;
          "
        >
          <img src="imgs/xbody-asset-4.svg" width="200" />
          <img src="imgs/xbody-asset-1.svg" width="200" />
        </div>
        <p style="text-align: center">
          please fill out your billing information
        </p>
      </template>
      <template #footer>
        <button
          type="button"
          class="semi-18 color-white form__submit"
          style="width: 150px; margin: auto; display: block; margin-top: 20px"
          @click="closeModal('billingInformation')"
        >
          {{ $t('OK') }}
        </button>
      </template>
    </modal-component>
    <modal-component :show="showEmailModal">
      <template #modal-body>
        <form
          @submit.prevent="updateUserEmail"
          style="width: 400px; margin: auto; margin-top: 20x"
        >
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-dark">
              {{ $t('currentEmail') }}
            </label>
            <input
              disabled
              type="text"
              class="medium-14 color-gray-warm"
              :placeholder="$t('fillIn')"
              v-model="userData.email"
            />
          </div>
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-dark">
              {{ $t('newEmail') }}
            </label>
            <input
              type="text"
              class="medium-14 color-gray-warm"
              :placeholder="$t('fillIn')"
              @input="checkEmail"
              v-model="changeEmailObject.email"
            />
          </div>
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-dark">
              {{ $t('repeat') }} {{ $t('newEmail') }}
            </label>
            <input
              type="text"
              class="medium-14 color-gray-warm"
              :placeholder="$t('fillIn')"
              v-model="changeEmailObject.emailConfirmation"
            />
          </div>
          <div class="d-flex justify-center settings-cards__btn form">
            <button
              type="button"
              class="semi-18 color-white form__submit"
              style="width: 150px; margin-right: 10px"
              @click="closeModal('email')"
            >
              {{ $t('Cancel') }}
            </button>
            <button type="submit" class="semi-18 color-white form__submit">
              {{ $t('save') }} <span class="icon icon-save ml-20"></span>
            </button>
          </div>
        </form>
      </template>
    </modal-component>
    <modal-component :show="showPasswordModal">
      <template #modal-body>
        <form
          @submit.prevent="changePassword"
          style="width: 400px; margin: auto; margin-top: 20x"
        >
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-dark">
              {{ $t('currentPassword') }}
            </label>
            <input
              type="password"
              class="medium-14 color-gray-warm"
              :placeholder="$t('fillIn')"
              v-model="changePasswordObject.oldPassword"
            />
          </div>
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-dark">
              {{ $t('newPassword') }}
            </label>
            <input
              type="password"
              class="medium-14 color-gray-warm"
              :placeholder="$t('fillIn')"
              v-model="changePasswordObject.password"
              @input="checkPassword"
            />
            <div class="form__message" v-if="showMessage">
              <p
                :class="{ invalid: !conditions.length }"
                class="regular-16 color-green"
              >
                {{ $t('minChar') }}
              </p>
              <p
                :class="{ invalid: !conditions.hasLowercase }"
                class="regular-16 color-green"
              >
                {{ $t('atLeasetLowercase') }}
              </p>
              <p
                :class="{ invalid: !conditions.hasUppercase }"
                class="regular-16 color-green"
              >
                {{ $t('atLeasetUppercase') }}
              </p>
              <p
                :class="{ invalid: !conditions.hasNumber }"
                class="regular-16 color-green"
              >
                {{ $t('atLeastNumber') }}
              </p>
              <p
                :class="{ invalid: !conditions.hasSpecialChar }"
                class="regular-16 color-green"
              >
                {{ $t('atLeastspecial') }}
              </p>
            </div>
          </div>
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-dark">
              {{ $t('repeat') }} {{ $t('newPassword') }}
            </label>
            <input
              type="password"
              class="medium-14 color-gray-warm"
              :placeholder="$t('fillIn')"
              @input="passwordCompare"
              v-model="changePasswordObject.passwordConfirmation"
            />
          </div>
          <div class="d-flex justify-center settings-cards__btn form">
            <button
              type="button"
              class="semi-18 color-white form__submit"
              style="width: 150px; margin-right: 10px"
              @click="closeModal('password')"
            >
              {{ $t('Cancel') }}
            </button>
            <button
              type="submit"
              class="semi-18 color-white form__submit"
              :disabled="!disableChangePassword"
            >
              {{ $t('save') }} <span class="icon icon-save ml-20"></span>
            </button>
          </div>
        </form>
      </template>
    </modal-component>
    <h2 class="settings-title semi-24 color-black-light">
      {{ $t('settings') }}
    </h2>
    <div class="settings-cards">
      <!-- <div class="settings-cards__item form">
        <h3 class="settings-cards__title form medium-20 color-gray-dark">
          {{ $t('Profile') }}
        </h3>
        
      </div>
      <div></div> -->
      <div class="user-profile-image">
        <div class="image-container" @click="updateAvatar">
          <img
            :src="userData.profile_photo"
            alt="user profile image"
            title="update picture"
            v-if="userData.profile_photo"
            class="user-image edit-hover"
          />
          <span
            v-else
            class="icon icon-avatar user-image edit-hover"
            title="update picture"
          ></span>
          <div class="edit-icon">
            <span class="icon icon-edit"></span>
          </div>
          <input
            type="file"
            style="display: none"
            ref="fileInput"
            @change="onFileChange"
            accept="image/*"
          />
        </div>
        <div>
          <button
            @click="openModal('password')"
            class="semi-18 color-white form__submit"
          >
            {{ $t('changePassword') }}
          </button>
          <button
            @click="openModal('email')"
            class="semi-18 color-white form__submit"
          >
            {{ $t('changeEmail') }}
          </button>
        </div>
        <form @submit.prevent="UpdateUserInfo">
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-dark">
              {{ $t('name') }}
            </label>
            <input
              type="text"
              class="medium-14 color-gray-warm"
              :placeholder="$t('fillIn')"
              v-model="formUserData.name"
            />
          </div>
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-dark">
              {{ $t('country') }}
            </label>
            <select
              required
              class="normal-14 color-black-light"
              v-model="formUserData.country_id"
            >
              <option
                v-for="country in countries"
                :key="country.id"
                :value="country.id"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
          <div class="d-flex justify-center settings-cards__btn form">
            <button type="submit" class="semi-18 color-white form__submit">
              {{ $t('save') }} <span class="icon icon-save ml-20"></span>
            </button>
          </div>
        </form>
      </div>
      <!-- <div class="settings-cards__item form"></div> -->
      <form
        class="settings-cards__item"
        style="grid-column: span 2"
        @submit.prevent="storeOrEditBillingInformation"
      >
        <p style="font-size: 12px" class="relative">
          Please feel free to contact us at
          <a href="mailto:sales@xbodyworld.com">sales@xbodyworld.com</a> if you
          would like to request any changes to your existing company data.
          Kindly specify the SAP ID you wish to modify and provide details
          regarding what aspect you would like to change and to what extent.
        </p>
        <div class="settings-cards__header d-flex align-center justify-space">
          <div
            class="settings-cards__title medium-20 color-gray-dark d-flex align-center"
          >
            <span class="icon icon-dollar mr-10"></span>
            <span class="medium-20 color-gray-dark relative">
              {{ $t('billingInfos') }} SAP CODE :
              {{ billingInformation.sap_code }}
              <span class="icon icon-info info-me">
                <img class="invoice-image" src="imgs/invoice.png" />
              </span>
              <br />
              <button
                v-if="!billingInformation.sap_code || !alreadyHaveInvoices"
                type="button"
                class="semi-18 color-white form__submit"
                style="width: 250px"
                @click="wantToAssignNewSAPCode = true"
              >
                {{ $t('already_have_SAP_Code') }}
              </button>
            </span>
          </div>
          <div
            class="settings-cards__input form"
            style="width: 50%"
            v-if="wantToAssignNewSAPCode"
          >
            <label class="medium-14 color-gray-cool"> SAPCODE </label>
            <input
              class="normal-14 color-black-light"
              v-model="newSAPCode"
              type="text"
              :placeholder="$t('fillIn')"
            />
            <button
              type="button"
              style="width: 50%; margin: auto; margin-top: 10px"
              class="semi-18 color-white form__submit"
              @click="fetchSAP()"
            >
              {{ $t('fetch') }}
            </button>
          </div>
        </div>
        <div class="billing-information">
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-cool">
              name of the address *
            </label>
            <input
              required
              :disabled="canEditBillingInformation"
              class="normal-14 color-black-light"
              v-model="billingInformation.address_name"
              type="text"
              :placeholder="$t('fillIn')"
            />
          </div>
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-cool">
              Name in the invoice *
            </label>
            <input
              required
              :disabled="canEditBillingInformation"
              class="normal-14 color-black-light"
              v-model="billingInformation.name"
              type="text"
              :placeholder="$t('fillIn')"
            />
          </div>
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-cool">
              {{ $t('phone_number') }} *
            </label>
            <input
              required
              :disabled="canEditBillingInformation"
              class="normal-14 color-black-light"
              v-model="billingInformation.phone_number"
              type="text"
              :placeholder="$t('fillIn')"
            />
          </div>
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-cool">
              {{ $t('emailAddress') }} *
            </label>
            <input
              required
              :disabled="canEditBillingInformation"
              class="normal-14 color-black-light"
              v-model="billingInformation.email"
              type="text"
              :placeholder="$t('fillIn')"
            />
          </div>
          <div class="d-flex" style="align-items: center">
            <label class="medium-14 color-gray-cool">
              {{ $t('user_is_company') }}
            </label>
            <input
              :disabled="canEditBillingInformation"
              v-model="billingInformation.user_is_company"
              type="checkbox"
            />
            <div
              class="d-flex"
              style="align-items: center; margin-left: 40px"
              v-if="billingInformation.user_is_company && isEU"
            >
              <label class="medium-14 color-gray-cool">
                {{ $t('has_eu_vat') }}
              </label>
              <input
                :disabled="canEditBillingInformation"
                v-model="hasEUVat"
                type="checkbox"
              />
            </div>
          </div>
          <div
            class="settings-cards__input form"
            v-if="billingInformation.user_is_company && hasEUVat"
          >
            <label class="medium-14 color-gray-cool">
              {{ $t('eu_tax_number') }}
            </label>
            <input
              :required="billingInformation.user_is_company && hasEUVat"
              :disabled="canEditBillingInformation"
              class="normal-14 color-black-light"
              v-model="billingInformation.eu_tax_number"
              type="text"
              :placeholder="$t('fillIn')"
            />
          </div>
          <div
            class="settings-cards__input form"
            v-if="billingInformation.user_is_company && hasHUVat"
          >
            <label class="medium-14 color-gray-cool">
              {{ $t('hungary_tax_number') }}
            </label>
            <input
              :required="billingInformation.user_is_company && hasHUVat"
              :disabled="canEditBillingInformation"
              class="normal-14 color-black-light"
              v-model="billingInformation.hungary_tax_number"
              type="text"
              :placeholder="$t('fillIn')"
            />
          </div>
          <div
            class="settings-cards__input form"
            v-if="billingInformation.user_is_company && !hasEUVat && !hasHUVat"
          >
            <label class="medium-14 color-gray-cool">
              {{ $t('non_eu_tax_number') }}
            </label>
            <input
              :required="
                billingInformation.user_is_company && !hasEUVat && !hasHUVat
              "
              :disabled="canEditBillingInformation"
              class="normal-14 color-black-light"
              v-model="billingInformation.non_eu_tax_number"
              type="text"
              :placeholder="$t('fillIn')"
            />
          </div>
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-cool">
              {{ $t('country') }} *
            </label>
            <select
              required
              class="normal-14 color-black-light"
              v-model="billingInformation.country_id"
              :disabled="canEditBillingInformation"
            >
              <option
                v-for="country in countries"
                :key="country.id"
                :value="country.id"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-cool">
              {{ $t('zipCode') }} *
            </label>
            <input
              required
              :disabled="canEditBillingInformation"
              class="normal-14 color-black-light"
              v-model="billingInformation.zip_code"
              type="text"
              :placeholder="$t('fillIn')"
            />
          </div>
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-cool">{{ $t('city') }} *</label>
            <input
              required
              :disabled="canEditBillingInformation"
              class="normal-14 color-black-light"
              v-model="billingInformation.city"
              type="text"
              :placeholder="$t('fillIn')"
            />
          </div>
          <!-- <div class="settings-cards__input form">
            <label class="medium-14 color-gray-cool">
              {{ $t('street_type') }} *
            </label>
            <input
              required
              :disabled="canEditBillingInformation"
              class="normal-14 color-black-light"
              v-model="billingInformation.street_type"
              type="text"
              :placeholder="$t('fillIn')"
            />
          </div> -->
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-cool">Address line *</label>
            <input
              required
              :disabled="canEditBillingInformation"
              class="normal-14 color-black-light"
              v-model="billingInformation.street_name"
              type="text"
              :placeholder="$t('fillIn')"
            />
          </div>
          <div class="settings-cards__input form">
            <label class="medium-14 color-gray-cool">
              Street number / House number
            </label>
            <input
              :disabled="canEditBillingInformation"
              class="normal-14 color-black-light"
              v-model="billingInformation.street_number"
              type="text"
              :placeholder="$t('fillIn')"
            />
          </div>
        </div>
        <button
          v-if="!billingInformation.sap_code || newSAPFetched"
          type="submit"
          class="semi-18 color-white form__submit"
          style="width: 148px; margin: auto; display: block"
        >
          {{ $t('save') }} <span class="icon icon-save ml-20"></span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import { mapActions, mapGetters } from 'vuex';
import ModalComponent from '@/components/ModalComponent';
export default {
  name: 'SettingView',
  components: {
    ModalComponent,
  },
  data() {
    return {
      studion: process.env.VUE_APP_STUDION_URL,
      lms: process.env.VUE_APP_LMS_URL,
      showNoBillingInformationModal: false,
      showPasswordModal: false,
      showEmailModal: false,
      firstTimeCreate: false,
      mySelectedValue: null,
      differentShippingInformation: false,
      showTaxNumber: false,
      isTheSamePassword: true,
      changeEmailObject: {
        oldEmail: '',
        email: '',
        emailConfirmation: '',
      },
      changePasswordObject: {
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
      },
      conditions: {
        length: true,
        hasSpecialChar: true,
        hasUppercase: true,
        hasLowercase: true,
        hasNumber: true,
      },
      editBtns: [],
      isEmail: true,
      isEmailTheSame: true,
      alreadyHaveBillingInformation: false,
      alreadyHaveInvoices: false,
      newSAPCode: null,
      wantToAssignNewSAPCode: false,
      newSAPFetched: false,
      countries: [],
      billingInformation: {
        sap_code: null,
        name: null,
        phone_number: null,
        email: null,
        country_id: null,
        state: null,
        address_name: null,
        zip_code: null,
        city: null,
        street_type: null,
        street_number: null,
        street_name: null,
        user_is_company: false,
        eu_tax_number: null,
        hungary_tax_number: null,
        non_eu_tax_number: null,
      },
      formUserData: {
        name: null,
        country_id: null,
      },
      hasEUVat: false,
      hasHUVat: false,
      isEU: false,
    };
  },
  watch: {
    'billingInformation.country_id': {
      handler(val) {
        this.handleCountryChange(val);
      },
      immediate: true, // This ensures that the handler is called immediately upon component creation
    },
    'billingInformation.user_is_company': {
      handler(val) {
        this.handleIsCompanyChange(val);
      },
      immediate: true, // This ensures that the handler is called immediately upon component creation
    },
    hasEUVat: {
      handler(val) {
        if (val && this.billingInformation.country_id == 137) {
          this.hasHUVat = false;
        } else {
          if (this.billingInformation.country_id == 137) {
            this.hasHUVat = true;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['updateShowLoader', 'updateUserData', 'updateUserLoggedIn']),
    openModal(modalName) {
      switch (modalName) {
        case 'billingInformation':
          this.showNoBillingInformationModal = true;
          break;
        case 'password':
          this.showPasswordModal = true;
          break;
        case 'email':
          this.showEmailModal = true;
          break;
      }
    },
    closeModal(modalName) {
      switch (modalName) {
        case 'billingInformation':
          this.showNoBillingInformationModal = false;
          break;
        case 'password':
          this.showPasswordModal = false;
          break;
        case 'email':
          this.showEmailModal = false;
          break;
      }
    },
    handleCountryChange(val) {
      this.isEU = this.countries.find((c) => c.id === val && c.eu === 1);
      if (!this.isEU) {
        this.hasEUVat = false;
      }
      this.hasHUVat = false;
      if (this.billingInformation.user_is_company) {
        if (val === 137) {
          this.hasHUVat = true;
        }
      }
    },
    handleIsCompanyChange(val) {
      this.isEU = this.countries.find(
        (c) => c.id === this.billingInformation.country_id && c.eu === 1
      );
      if (!this.isEU) {
        this.hasEUVat = false;
      }
      this.hasHUVat = false;
      if (val) {
        if (this.billingInformation.country_id === 137) {
          this.hasHUVat = true;
        }
      }
    },
    checkPassword({ target: { value } }) {
      const lowerCaseLetters = /[a-z]{1,}/g;
      const upperCaseLetters = /[A-Z]{1,}/g;
      const numbers = /[0-9]{1,}/g;
      const specialCharacters = /[!@#$%^&*(),.?":{}|<>+-]{1,}/g;
      this.conditions.hasSpecialChar = specialCharacters.test(value);
      this.conditions.hasNumber = numbers.test(value);
      this.conditions.hasUppercase = upperCaseLetters.test(value);
      this.conditions.hasLowercase = lowerCaseLetters.test(value);
      this.conditions.length = value.length >= 8;
    },
    checkEmail(event) {
      // eslint-disable-next-line no-useless-escape
      const email = /^[^@]+(\+\w+)?@\w+(\.\w+)+$/;
      if (!email.test(event.target.value)) {
        event.target.classList.add('invalid');
      } else {
        event.target.classList.remove('invalid');
      }
    },
    setButtonEvent() {
      this.editBtns = [...document.querySelectorAll('div[data-btn="edit"]')];
      this.editBtns.forEach((btn) => {
        btn.addEventListener('click', this.setInputActive);
        btn.previousElementSibling.addEventListener('blur', this.setBlueEvent);
      });
    },
    passwordCompare(event) {
      if (this.changePasswordObject.password !== event.target.value) {
        event.target.classList.add('invalid');
        this.isTheSamePassword = true;
      } else {
        this.isTheSamePassword = false;
        event.target.classList.remove('invalid');
      }
    },
    setInputActive({ currentTarget }) {
      currentTarget.previousElementSibling.classList.toggle('active');
      const end = currentTarget.previousElementSibling.value.length;
      currentTarget.previousElementSibling.setSelectionRange(end, end);
      currentTarget.previousElementSibling.focus();
    },
    setBlueEvent({ currentTarget }) {
      currentTarget.classList.toggle('active');
    },
    async UpdateUserInfo() {
      const { id, email } = JSON.parse(localStorage.getItem('userData'));
      const postData = {
        _method: 'PATCH',
        email: email,
        name: this.formUserData.name,
        country_id: this.formUserData.country_id,
      };
      const { data } = await api.updateUserEmail(id, postData);
      const { code } = data;
      if (code === 200) {
        const { messages, user } = data;
        const { general } = messages;
        this.$toast.success(general);
        this.updateUserData({ ...user });
      }
    },
    async getUserData() {
      this.formUserData = JSON.parse(localStorage.getItem('userData'));
      //await api.getUserData(id);
    },
    async updateUserEmail() {
      const { id, name, country_id } = JSON.parse(
        localStorage.getItem('userData')
      );
      if (
        this.changeEmailObject.email !==
        this.changeEmailObject.emailConfirmation
      ) {
        this.$toast.error('email confirmation dose not match');
        return;
      }
      const postData = {
        _method: 'PATCH',
        email: this.changeEmailObject.emailConfirmation,
        name: name,
        country_id: country_id,
      };

      const { data } = await api.updateUserEmail(id, postData);
      const { code } = data;
      if (code === 200) {
        const { messages, user } = data;
        const { general } = messages;
        this.$toast.success(general);
        this.updateUserData({ ...user });
        this.closeModal('email');
      }
    },
    async changePassword() {
      if (!Object.values(this.changePasswordObject).every((value) => !!value)) {
        this.$toast.error('Fill password inputs!!');
        return;
      }
      const { id } = JSON.parse(localStorage.getItem('userData'));
      const patchData = {
        _method: 'PATCH',
        old_password: this.changePasswordObject.oldPassword,
        password: this.changePasswordObject.password,
        password_confirmation: this.changePasswordObject.passwordConfirmation,
      };

      const res = await api.changePassword(id, patchData);
      const { status } = res;
      if (status === 200) {
        const { data = null } = res;
        const { messages } = data;
        const { general } = messages;
        this.changePasswordObject.oldPassword = '';
        this.changePasswordObject.password = '';
        this.changePasswordObject.passwordConfirmation = '';
        this.$toast.success(general);
        this.closeModal('password');
      } else {
        const { data = null } = res;
        const { messages } = data;
        this.$toast.error(
          `${messages['errors-validation'].old_password[0]} ${messages['errors-validation'].password[0]}`
        );
      }
    },
    async fetchSAP() {
      const { id } = JSON.parse(localStorage.getItem('userData'));
      await api.fetchSAP(id, this.newSAPCode).then((data) => {
        if (data.data) {
          let country = this.countries.filter((item) => {
            return item.code == data.data.data.billing_info.adresses[0].country;
          })[0];
          let street_type =
            data.data.data.billing_info.adresses[0].state == ''
              ? data.data.data.billing_info.adresses[0].street_type
              : data.data.data.billing_info.adresses[0].state;
          this.billingInformation = {
            sap_code: data.data.data.all_sap_fields.CardCode,
            name: data.data.data.all_sap_fields.CardName,
            phone_number: data.data.data.all_sap_fields.Phone1,
            email: data.data.data.all_sap_fields.EmailAddress,
            country_id: country.id,
            state: street_type,
            address_name: data.data.data.billing_info.adresses[0].name,
            zip_code: data.data.data.billing_info.adresses[0].zip_code,
            city: data.data.data.billing_info.adresses[0].city,
            street_type: street_type,
            street_number:
              data.data.data.billing_info.adresses[0].street_number,
            street_name: data.data.data.billing_info.adresses[0].street_name,
            eu_tax_number: data.data.data.tax_info.eu_tax_number,
            hungary_tax_number: data.data.data.tax_info.hungary_tax_number,
            non_eu_tax_number: data.data.data.tax_info.non_eu_tax_number,
            user_is_company: false,
          };
          if (
            this.billingInformation.eu_tax_number != '' ||
            this.billingInformation.hungary_tax_number != '' ||
            this.billingInformation.non_eu_tax_number != ''
          ) {
            this.billingInformation.user_is_company = true;
          }

          this.newSAPFetched = true;
          this.$toast.success('fetched');
        } else {
          this.$toast.error('SAP dose not exist');
        }
      });
    },
    async onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const formData = new FormData();
      formData.append('profile_photo', files[0], files[0].name);
      formData.append('_method', 'PATCH');
      const { id } = this.userData;
      const res = await api.updateUserAvatar(id, formData);
      const { data } = res;
      const { user, code, messages } = data;
      if (code === 200) {
        const { general } = messages;
        this.$toast.success(general);
        this.updateUserLoggedIn(true);
        this.updateUserData({ ...user });
      }
    },
    updateAvatar() {
      this.$refs.fileInput.click();
    },
    async fetchCountries() {
      await api.countries().then((data) => {
        if (data.data.data) {
          this.countries = data.data.data;
        }
      });
    },
    async fetchInvoices() {
      await api.getInvoices().then((data) => {
        if (data.data.data?.length) {
          this.alreadyHaveInvoices = true;
        }
      });
    },
    async fetchBillingInformation() {
      const { id } = JSON.parse(localStorage.getItem('userData'));
      await api.fetchBillingInformationFront(id).then((data) => {
        if (data.data.data.billing_information?.name) {
          this.alreadyHaveBillingInformation = true;
          this.billingInformation = data.data.data.billing_information;
          if (this.billingInformation.eu_tax_number) {
            this.hasEUVat = true;
          }
        } else {
          this.openModal('billingInformation');
          this.firstTimeCreate = true;
        }
        this.billingInformation.sap_code = data.data.data.sap_code;
      });
    },
    async storeOrEditBillingInformation() {
      const { id } = JSON.parse(localStorage.getItem('userData'));
      if (this.billingInformation.user_is_company) {
        if (this.hasEUVat) {
          this.billingInformation.non_eu_tax_number = null;
          this.billingInformation.hungary_tax_number = null;
        }
        if (this.hasHUVat) {
          this.billingInformation.non_eu_tax_number = null;
          this.billingInformation.eu_tax_number = null;
        }
        if (!this.hasEUVat && !this.hasHUVat) {
          this.billingInformation.hungary_tax_number = null;
          this.billingInformation.eu_tax_number = null;
        }
      }
      if (this.newSAPFetched) {
        await api
          .BillingInformationNewSAP(id, this.billingInformation)
          .then((data) => {
            if (data?.data?.data?.billing_information) {
              this.billingInformation = data.data.data.billing_information;
              this.$toast.success('saved');
              if (this.firstTimeCreate) {
                setTimeout(() => {
                  this.$toast.success(
                    'Now you can use our platforms without interruption ! Enjoy'
                  );
                  this.$router.push({ name: 'Home' });
                }, 1000);
              }
            }
            this.billingInformation.sap_code = data.data.data.sap_code;
          });
        return;
      }

      if (this.alreadyHaveBillingInformation) {
        await api
          .editBillingInformation(id, this.billingInformation)
          .then((data) => {
            if (data.data.code == 422) {
              alert('SAP CODE is not valid');
              return;
            }
            if (data.data.data.billing_information) {
              this.billingInformation = data.data.data.billing_information;
            }
            this.billingInformation.sap_code = data.data.data.sap_code;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await api
          .storeBillingInformation(id, this.billingInformation)
          .then((data) => {
            if (data.data.data.billing_information) {
              this.billingInformation = data.data.data.billing_information;
            }
            this.billingInformation.sap_code = data.data.data.sap_code;
            this.alreadyHaveBillingInformation = true;
          });
      }
      this.$toast.success('saved');
      if (this.firstTimeCreate) {
        setTimeout(() => {
          this.$toast.success(
            'Now you can use our platforms without interruption ! Enjoy'
          );
          this.$router.push({ name: 'Home' });
        }, 1000);
      }
    },
  },
  computed: {
    ...mapGetters(['userData']),
    showMessage() {
      return !Object.keys(this.conditions).every(
        (key) => this.conditions[key] === true
      );
    },
    disableChangePassword() {
      return (
        Object.keys(this.conditions).every(
          (key) => this.conditions[key] === true
        ) && !this.isTheSamePassword
      );
    },
    canEditBillingInformation() {
      return this.billingInformation.sap_code ? true : false;
    },
  },
  async mounted() {
    this.setButtonEvent();
    this.getUserData();
    this.updateShowLoader(false);
    await this.fetchCountries();
    await this.fetchInvoices();
    await this.fetchBillingInformation();
  },
};
</script>

<style lang="scss" src="./SettingView.scss" scoped></style>
