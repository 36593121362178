<template>
  <div class="login">
    <div class="bg-white d-flex w-full justify-between">
      <div class="w-40 h-screen login-left">
        <img src="/imgs/cover2.png" alt="login img" class="w-full h-full" />
      </div>
      <div class="login-right">
        <form @submit.prevent="login">
          <div class="d-flex justify-center mb-5">
            <img
              src="~@/assets/icons/logo.svg"
              alt="xbody"
              style="height: 50px"
            />
          </div>
          <h2>{{ $t('login') }}</h2>
          <div class="form-item">
            <div class="d-flex align-center justify-space">
              <label class="semi-16 color-black">{{
                $t('emailAddress')
              }}</label>
              <p
                v-if="errorWrongEmail"
                :class="{ invalid: false }"
                class="regular-16 color-red"
              >
                {{ $t('wrongEmail') }}
              </p>
            </div>
            <input
              type="email"
              class="color-gray-warm"
              :placeholder="$t('yourEmailAddress')"
              v-model="postObject.email"
              @input="handleEmailChange"
            />
          </div>
          <div class="form-item">
            <label class="d-flex align-center justify-space semi-16">
              {{ $t('password') }}
              <a class="color-red regular-16 pointer" @click="goForgotPassword">
                {{ $t('forgotPassword') }}
              </a>
            </label>
            <input
              type="password"
              class="color-gray-warm"
              :placeholder="$t('yourPassword')"
              v-model="postObject.password"
            />
          </div>
          <button type="submit">
            {{ $t('login') }}
          </button>
          <div
            class="flex items-center justify-center"
            style="margin-top: 10px"
          >
            <p class="hint">
              {{ $t('dontHaveAccount') }}
              <a @click="goToRegistation">{{ $t('registration') }}</a>
            </p>
          </div>
        </form>
        <div class="auth-language" style="margin-top: 10px">
          <LanguageSelector />
        </div>
        <!-- <div id="girl_img" class="fixed bottom-0 h-96 girl-image">
          <img
            src="	https://asset.xbodyworld.com/assets/img/xbody_girl.png"
            class="w-full h-full"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector/LanguageSelector.vue';
import api from '@/api';
import { mapActions } from 'vuex';

export default {
  name: 'LoginView',
  components: {
    LanguageSelector,
  },
  data() {
    return {
      isEmail: true,
      isSamePassword: true,
      toastOption: {
        position: '',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      },
      postObject: {
        email: null,
        password: null,
        name: null,
      },
      errorWrongEmail: false,
    };
  },
  mounted() {
    /* setTimeout(() => {
      document.getElementById('girl_img').classList.add('show');
    }, 500);*/
  },
  methods: {
    ...mapActions(['updateUserLoggedIn', 'updateUserData']),
    goForgotPassword() {
      this.$router.push({ name: 'ForgotPassword' });
    },
    goToRegistation() {
      this.$router.push({ name: 'Registration' });
    },
    handleEmailChange() {
      this.errorWrongEmail = false;
    },
    async login() {
      const postBody = {
        email: this.postObject.email,
        password: this.postObject.password,
        user: {
          service: {
            login_uri: `${location.origin}/login`,
          },
        },
      };
      this.errorWrongEmail = false;

      await api.getCookie();
      const res = await api.userLogin(postBody);
      const { data, status } = res;
      if (status === 422) {
        const { messages } = data;
        if (messages['errors-validation'] === undefined) {
          const { error } = messages;
          this.$toast.error(error);
        } else if (messages['errors-validation'].email[0])
          this.errorWrongEmail = true;
      }
      const { user, code, messages } = data;
      if (code === 200) {
        const { general } = messages;
        this.$toast.success(general);
        this.updateUserLoggedIn(true);
        this.updateUserData({ ...user });
        if (!user.billing_information) {
          this.$router.push({ name: 'Settings' });
        } else {
          this.$router.push({ name: 'Home' });
        }
      }
    },
  },
};
</script>
<style lang="scss" src="./LoginView.scss" scoped></style>
