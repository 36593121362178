<template>
  <div class="comfirm">
    <navbar-component class="justify-center" />
    <div class="comfirm-wrapper d-flex">
      <div class="comfirm-left">
        <div class="img-wrapper">
          <img src="~@/assets/imgs/login.svg" alt="side img" />
        </div>
      </div>
      <div class="comfirm-rigth">
        <h2 class="comfirm-title">{{ $t('thank') }}</h2>
        <p class="comfirm-subtitle semi-16 color-gray-cool">
          {{ $t('verifyEmail') }}
        </p>
        <p class="comfirm-subtitle semi-16 color-gray-cool">
          {{ $t('pleaseConfirmAddress') }}
        </p>
        <form class="form">
          <p class="comfirm-forgot semi-16 color-gray-dark">
            {{ $t('gotEmail') }}
            <a @click.prevent="resendEmail" class="color-red pointer">
              {{ $t('resend') }}
            </a>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent';

import api from '@/api';

export default {
  name: 'ResetPassword',
  components: {
    NavbarComponent,
  },
  data() {
    return {
      isEmail: true,
      isSamePassword: true,
      toastOption: {
        position: 'top-center',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      },
      postObject: {
        email: null,
      },
    };
  },
  methods: {
    async resendEmail() {
      await api.getCookie();
      const postData = {
        email: this.postObject.email,
      };
      const res = await api.emailVerification(postData);
      const { data } = res;
      const { messages } = data;
      const { general } = messages;
      this.$toast.success(general, this.toastOption);
    },
    goResetPassword() {
      this.$router.push({ name: 'ResetPassword' });
    },
  },
  mounted() {
    this.postObject.email = atob(localStorage.getItem('confirmEmail'));
  },
};
</script>

<style lang="scss" src="./ComfirmEmailView.scss" scoped></style>
