<template>
  <multiselect
    v-model="selectedLanguageObj"
    :options="supportedLanguages"
    label="name"
    track-by="key"
    :placeholder="$t('select_language')"
    :show-labels="false"
    deselect-label=""
    @input="changeLanguage"
  >
    <template slot="singleLabel" slot-scope="{ option }">
      <span :class="'flag-icon flag-icon-' + option.key"></span>
      {{ option.name }}
    </template>
  </multiselect>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect';
export default {
  name: 'LanguageSelector',
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedLanguageObj: null,
      supportedLanguages: [
        { key: 'gb', name: 'English' },
        { key: 'hu', name: 'Hungarian' },
        { key: 'de', name: 'Deutsche' },
        { key: 'it', name: 'Italian' },
        { key: 'fr', name: 'French' },
        // Add more supported languages with keys and names
      ],
    };
  },
  computed: {
    selectedLanguage: {
      get() {
        const storedLanguage = this.getStoredLanguage();
        return storedLanguage || this.supportedLanguages[0]; // Default to the first option
      },
      set(selectedOption) {
        this.changeLanguage(selectedOption.key);
      },
    },
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.selectedLanguageObj.key;
      localStorage.setItem('lang', this.selectedLanguageObj.key);
      location.reload();
    },
    getCurrentLangue() {
      let currentLang = { key: 'gb', name: 'English' };
      this.supportedLanguages.forEach((lang) => {
        if (this.$i18n.locale == lang.key) {
          currentLang = lang;
        }
      });
      return currentLang;
    },
  },
  mounted() {
    this.selectedLanguageObj = this.getCurrentLangue();
  },
};
</script>
<style lang="scss">
@import '~flag-icon-css/css/flag-icons.min.css';

.multiselect__option--highlight {
  background: #d62b33 !important;
  font-size: 0.9rem;
  height: 1rem;
}
.multiselect__option--highlight:after {
  background: #d62b33 !important;
}
.multiselect__option--selected {
  background: #41b883 !important;
  color: #35495e;
  font-weight: 700;
  pointer-events: none;
  color: white;
}
.multiselect__option--selected:after {
  color: white;
  pointer-events: none;
}
</style>
