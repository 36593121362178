<template>
  <div class="invoice-wrapper page page-padding">
    <table-component :title="$t('invoices')" :filters="false">
      <template #header>
        <thead class="table-header">
          <tr>
            <th
              v-for="(header, index) in tableHeader"
              :key="index"
              class="semi-18 color-gray-cool"
              :class="`text-align-${header.aling}`"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
      </template>
      <template #data v-if="tableData.length > 0">
        <tbody class="table-body">
          <tr v-for="data in tableData" :key="data.id">
            <td class="medium-20 color-gray-dark text-align-center">
              {{ data.date }}
            </td>
            <td class="medium-20 color-gray-dark text-align-center">
              {{ data.invoiceNum }}
            </td>
            <td class="medium-20 color-gray-dark text-align-center">
              <img
                :src="
                  data.platformName == 'studion'
                    ? 'imgs/xbody-asset-4.svg'
                    : 'imgs/xbody-asset-1.svg'
                "
                width="200"
              />
            </td>
            <td class="medium-20 color-gray-dark text-align-center">
              {{ data.invoiceAmount }}
            </td>
            <td>
              <chip-component
                outlined
                :text="data.status.text"
                :class="`color-${data.status.color}`"
              />
            </td>
            <td>
              <button
                type="button"
                class="table-btn d-flex-inline align-center"
                @click="downloadPdf(data.id)"
              >
                <span class="semi-14 color-white mr-10">
                  {{ $t('download') }}
                </span>
                <span class="icon icon-download"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </template>
      <template #no-data v-else>
        <div class="d-flex align-center justify-center mt-60">
          <h2 class="medium-24 color-gray-cool">No data</h2>
        </div>
      </template>
    </table-component>
  </div>
</template>

<script>
import TableComponent from '@/components/TableComponent';
import ChipComponent from '@/components/ChipComponent';

import { mapActions } from 'vuex';
import api from '@/api';

export default {
  name: 'InvoicesView',
  components: {
    TableComponent,
    ChipComponent,
  },
  data() {
    return {
      tableHeader: [
        {
          text: this.$t('date'),
          aling: 'center',
        },
        {
          text: this.$t('invoiceNumber'),
          aling: 'center',
        },
        {
          text: this.$t('nameOfPlatform'),
          aling: 'center',
        },
        {
          text: this.$t('invoiceAmount'),
          aling: 'center',
        },
        {
          text: this.$t('status'),
          aling: 'center',
        },
        {
          text: this.$t('download'),
          aling: 'center',
        },
      ],
      tableData: [],
    };
  },
  methods: {
    ...mapActions(['updateShowLoader']),
    async getInvoices() {
      const res = await api.getInvoices();
      console.log(res);
      const { data } = res;
      const { data: tableData, code } = data;
      if (code === 422) {
        const { messages } = data;
        const { error } = messages;
        this.updateShowLoader(false);
        this.$toast.error(error);
        return;
      }
      this.tableData = tableData.map(
        ({
          sap_doc_entry,
          sap_doc_date,
          sap_doc_total,
          sap_doc_num,
          sap_doc_currency,
          user_service_name,
          payment_status,
        }) => {
          return {
            id: sap_doc_entry,
            invoiceNum: sap_doc_num,
            invoiceAmount: sap_doc_total + ' ' + sap_doc_currency,
            platformName: user_service_name,
            date: this.dateFormat(sap_doc_date),
            status: this.getStatus(payment_status),
          };
        }
      );
      console.log(tableData);
      this.updateShowLoader(false);
    },
    getStatus(status) {
      switch (status) {
        case 'paid':
          return {
            text: 'Paid',
            value: true,
            color: 'green',
          };
        case 'unpaid':
          return {
            text: 'Unpaid',
            value: false,
            color: 'red',
          };
        default:
          return {
            text: 'Pending',
            value: null,
            color: 'gray',
          };
      }
    },
    dateFormat(date) {
      const dateObject = new Date(date);
      const year = dateObject.getFullYear();
      const day =
        dateObject.getDay() < 10
          ? `0${dateObject.getDay()}`
          : dateObject.getDay();
      const month =
        dateObject.getMonth() < 10
          ? `0${dateObject.getMonth()}`
          : dateObject.getMonth();
      return `${day}/${month}/${year}`;
    },
    async downloadPdf(id) {
      this.updateShowLoader(true);
      const { data } = await api.getInvoiceData(id);
      const { data: invoiceData } = data;
      const { download_pdf } = invoiceData;
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', download_pdf);
      link.click();
      link.remove();
      this.updateShowLoader(false);
    },
  },
  mounted() {
    this.getInvoices();
  },
};
</script>

<style lang="scss" src="./InvoicesView.scss" scoped></style>
